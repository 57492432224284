<template>
  <div style="height: 100%" class="put_center">
    <v-alert type="error" :value="!!error" border="left">
      {{ error }}
    </v-alert>
    <v-carousel
      auto
      :continuous="false"
      :cycle="false"
      :show-arrows="true"
      show-arrows-on-hover
      :hide-delimiters="mdUp ? false : true"
      delimiter-icon="mdi-minus"
      :height="mdUp ? '900' : '800'"
      ref="carousel"
    >
      <template v-slot:prev="{ on, attrs }" v-if="mdUp">
        <v-btn
          :style="{
            backgroundColor: user?.brandColor ? user?.brandColor : '#97c81f',
          }"
          v-bind="attrs"
          v-on="on"
          depressed
          small
          ><v-icon left dark>mdi-chevron-left</v-icon> Previous</v-btn
        >
      </template>
      <template v-slot:next="{ on, attrs }" v-if="mdUp">
        <v-btn
          :style="{
            backgroundColor: user?.brandColor ? user?.brandColor : '#97c81f',
          }"
          v-bind="attrs"
          v-on="on"
          small
          depressed
          >Next <v-icon right dark>mdi-chevron-right</v-icon></v-btn
        >
      </template>
      <div v-for="(recipe, i) in allRecipes" :key="i">
        <v-carousel-item v-if="recipe.name == 'cover'">
          <v-sheet height="100%" :class="mdUp ? 'pa-4' : 'pa-0'">
            <v-row justify="center" align="center">
              <v-col sm="12" md="9" lg="6" xl="5">
                <v-card
                  class="px-3 custom-card"
                  :style="{ height: mdUp ? '830px' : '800px' }"
                >
                  <v-row justify="end" align="end">
                    <v-col lg="9" xl="9" cols="7" class="text-end mr-3">
                      <div
                        class="font-weight-bold"
                        :class="mdUp ? 'text-h4' : 'text-subtitle-1'"
                        :style="{
                          color: user?.brandColor ? user?.brandColor : '#97c81f',
                        }"
                      >
                        {{ upperFirst(plan?.name) }}
                      </div>
                      <div class="text-caption">
                        A Meal Plan eBook by {{ user?.firstName }}
                      </div>
                    </v-col>
                    <v-col cols="4" lg="2" xl="2">
                      <v-img
                        :src="user?.customLogoUrl"
                        contain
                        height="70px"
                        width="110px"
                      >
                        <template v-slot:placeholder>
                          <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular
                              indeterminate
                              :color="user?.brandColor ? user.brandColor : '#97c81f'"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>
                  </v-row>
                  <div class="mt-3 px-8">
                    <v-divider />
                  </div>
                  <div
                    v-show="plan?.requestStatus == 'approved'"
                    class="px-5 py-3 stamp-container"
                    style="position: relative"
                  >
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/cq-nutrition.appspot.com/o/misc%2FDIETITIAN%20APPROVED%20(TRANSPARENT).png?alt=media&token=0e14d93b-7320-4683-a408-8015e9b3d346"
                      alt="Approval Seal"
                      class="stamp-seal"
                      width="200"
                      height="200"
                    />
                  </div>
                  <v-row justify="center" class="py-3">
                    <div>
                      <v-img :src="user?.customLogoUrl" width="300" height="300" contain>
                        <template v-slot:placeholder>
                          <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular
                              indeterminate
                              :color="user?.brandColor ? user.brandColor : '#97c81f'"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </div>
                  </v-row>
                  <div
                    class="py-3"
                    style="overflow: hidden auto"
                    :style="{ height: mdUp ? '285px' : '250px' }"
                  >
                    <v-row class="mt-5 px-10">
                      <v-col cols="12">
                        <div class="ql-editor" v-html="plan?.mealplanDescription"></div>
                      </v-col>
                    </v-row>
                  </div>
                  <div
                    class="bg"
                    :style="{
                      backgroundColor: user?.brandColor ? user?.brandColor : '#97c81f',
                    }"
                  />
                  <div class="text-caption bg-text">Copyright 2023 ©</div>
                  <div class="social-medias d-flex justify-end" style="gap: 0.5rem">
                    <a v-if="user.facebook" :href="user.facebook" target="_blank">
                      <v-icon medium color="white"> mdi-facebook </v-icon>
                    </a>
                    <!-- <v-icon
                        medium
                        color="white"
                        v-else
                    >
                        mdi-facebook
                    </v-icon> -->
                    <a v-if="user.website" :href="user.website" target="_blank">
                      <v-icon medium color="white"> mdi-web </v-icon>
                    </a>
                    <!-- <v-icon
                        medium
                        color="white"
                        v-else
                    >
                        mdi-web
                    </v-icon> -->
                    <a v-if="user.instagram" :href="user.instagram" target="_blank">
                      <v-icon medium color="white"> mdi-instagram </v-icon>
                    </a>
                    <!-- <v-icon
                        medium
                        color="white"
                        v-else
                    >
                        mdi-instagram
                    </v-icon> -->
                  </div>
                  <div v-if="user?.isStudent" class="text-body2 red--text sample-text">
                    *WARNING: This eBook was created by a student
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-sheet>
        </v-carousel-item>
        <!-- <v-carousel-item v-else-if="recipe.name == 'table'">
          <v-sheet height="100%" :class="mdUp ? 'pa-4' : 'pa-0'">
            <v-row justify="center" align="center">
              <v-col sm="12" md="9" lg="6" xl="5">
                <v-card
                  class="px-3 custom-card"
                  :style="{ height: mdUp ? '830px' : '800px' }"
                >
                  <v-row justify="end" align="end">
                    <v-col lg="9" xl="9" cols="7" class="text-end mr-3">
                      <div
                        class="font-weight-bold"
                        :class="mdUp ? 'text-h4' : 'text-subtitle1'"
                        :style="{
                          color: user?.brandColor
                            ? user?.brandColor
                            : '#97c81f',
                        }"
                      >
                        Table of Contents
                      </div>
                      <div class="text-caption">
                        A Meal Plan eBook by {{ user?.firstName }}
                      </div>
                    </v-col>
                    <v-col cols="4" lg="2" xl="2">
                      <v-img
                        :src="user?.customLogoUrl"
                        contain
                        height="70px"
                        width="110px"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              :color="
                                user?.brandColor ? user.brandColor : '#97c81f'
                              "
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>
                  </v-row>
                  <div
                    class="py-3"
                    style="overflow: hidden auto"
                    :style="{ height: mdUp ? '670px' : '580px' }"
                  >
                    <v-row
                      justify="center"
                      align="center"
                      v-for="(header, i) in headers"
                      :key="i"
                    >
                      <v-col cols="12">
                        <v-row class="px-2">
                          <v-col cols="12">
                            <div class="text-capitalize text-body-1 grey--text"
                            >
                              {{ header }}
                              <v-divider/>
                            </div>
                          </v-col>
                        </v-row>
                        <v-row
                          v-for="recipe in groupedRecipes[header]"
                          :key="recipe.id"
                          align="center"
                        >
                          <v-col cols="8" class="pl-8">
                            <div
                              class="text-subtitle-2 table-content-item"
                              @click="goTo(getIndexNo(recipe.id) + 1)"
                              :style="{ color: user.brandColor }"
                            >
                              {{ recipe.name }}
                            </div>
                          </v-col>
                          <v-col cols="3" class="pa-0 pr-2">
                            <div class="text-subtitle-2 text-end">{{ getIndexNo(recipe.id) + 3}}</div>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row justify="center" v-if="smDown">
                      <div class="text-body-1 grey--text my-3">------END------</div>
                    </v-row>
                  </div>
                  <div
                    class="bg"
                    :style="{
                      backgroundColor: user?.brandColor
                        ? user?.brandColor
                        : '#97c81f',
                    }"
                  />
                  <div class="text-caption bg-text">Copyright 2023 ©</div>
                  <div v-if="user?.isStudent" class="text-body2 red--text sample-text">*WARNING: This eBook was created by a student</div>
                </v-card>
              </v-col>
            </v-row>
          </v-sheet>
        </v-carousel-item> -->
        <v-carousel-item v-else-if="recipe.name == 'mealplans'">
          <v-sheet height="100%" :class="mdUp ? 'pa-4' : 'pa-0'">
            <v-row justify="center" align="center">
              <v-col sm="12" md="9" lg="6" xl="5">
                <v-card
                  class="px-3 custom-card"
                  :style="{ height: mdUp ? '830px' : '800px' }"
                >
                  <v-row justify="end" align="end">
                    <v-col lg="9" xl="9" cols="7" class="text-end mr-3">
                      <div
                        class="font-weight-bold"
                        :class="mdUp ? 'text-h4' : 'text-subtitle1'"
                        :style="{
                          color: user?.brandColor ? user?.brandColor : '#97c81f',
                        }"
                      >
                        Meal Plan
                      </div>
                      <div class="text-caption">
                        A Meal Plan eBook by {{ user?.firstName }}
                      </div>
                    </v-col>
                    <v-col cols="4" lg="2" xl="2">
                      <v-img
                        :src="user?.customLogoUrl"
                        contain
                        height="70px"
                        width="110px"
                      >
                        <template v-slot:placeholder>
                          <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular
                              indeterminate
                              :color="user?.brandColor ? user.brandColor : '#97c81f'"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>
                  </v-row>
                  <div
                    class="py-3 mt-3"
                    style="overflow: hidden auto"
                    :style="{ height: mdUp ? '670px' : '580px' }"
                  >
                    <v-row
                      justify="center"
                      align="center"
                      v-for="(day, i) in days"
                      :key="i"
                    >
                      <v-col cols="12">
                        <v-row class="px-2">
                          <v-col cols="12">
                            <div class="d-flex justify-space-between flex-wrap">
                              <div class="text-capitalize text-body-1 font-weight-bold">
                                {{ day }}
                              </div>
                              <div v-if="day == 'Monday'">
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      v-on="on"
                                      x-small
                                      color="white"
                                      class="black--text"
                                      depressed
                                      @click="startDownloadRecipes(0)"
                                    >
                                      Recipes
                                      <v-icon small>mdi-download-multiple</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Download a zip file of the recipes</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      v-on="on"
                                      x-small
                                      color="white"
                                      class="black--text"
                                      depressed
                                      @click="mealPlanDownload"
                                    >
                                      Meal Plan
                                      <v-icon small>mdi-download</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Download the Mealplan PDF</span>
                                </v-tooltip>
                                <v-btn
                                  x-small
                                  color="white"
                                  class="black--text"
                                  depressed
                                  v-if="
                                    mealShoppingListIds?.length < filteredMeals?.length
                                  "
                                  @click="addAllMealsToShoppingList()"
                                >
                                  Add All Meals
                                  <v-icon small>mdi-cart-variant</v-icon>
                                </v-btn>
                                <v-btn
                                  x-small
                                  color="white"
                                  class="black--text"
                                  depressed
                                  v-else
                                  @click="removeAllMealsFromShoppingList()"
                                >
                                  Remove All Meals
                                  <v-icon small>mdi-cart-off</v-icon>
                                </v-btn>
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                        <div v-for="(meal, j) in groupByDate[day]" :key="j">
                          <v-card
                            class="my-2 mr-3 no-shadow"
                            outlined
                            v-if="getRecipeName(meal.recipe) != 'no meal'"
                          >
                            <v-card-text>
                              <v-row align="center" class="pr-3">
                                <v-col cols="9" class="pa-2">
                                  <div class="d-flex flex-start" style="gap: 0.5rem">
                                    <!-- <img
                                      :src="getRecipeImg(meal.recipe)"
                                      contain
                                      width="70"
                                      height="70"
                                      style="object-fit: cover"
                                    /> -->
                                    <div style="height: 70px; width: 70px; object-fit: cover">
                                      <recipe-image :recipe="getRecipe(meal.recipe)" size="small" :updateFields="false" />
                                    </div>
                                    <div>
                                      <div class="text-caption grey--text">
                                        {{ upperFirst(meal.time) }}
                                      </div>
                                      <a
                                        class="text-subtitle-2 font-weight-bold table-content-item"
                                        :style="{ color: user.brandColor }"
                                        @click="goTo((getIndexNo(meal.id) - valueToMinus), meal)"
                                      >
                                        {{ getRecipeName(meal.recipe) }}
                                      </a>
                                    </div>
                                  </div>
                                </v-col>
                                <v-col cols="3" class="pa-0 pr-2">
                                  <div
                                    class="text-subtitle-2 text-end cursor-pointer"
                                    @click="addMealToShoppingList(meal)"
                                    v-if="!mealShoppingListIds.includes(meal.id)"
                                  >
                                    <v-icon>mdi-cart-variant</v-icon>
                                  </div>
                                  <div
                                    class="text-subtitle-2 text-end cursor-pointer"
                                    @click="removeMealFromShoppingList(meal)"
                                    v-else
                                  >
                                    <v-icon>mdi-cart-off</v-icon>
                                  </div>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </div>
                      </v-col>
                    </v-row>
                    <div
                      class="shoppingcart"
                      v-if="shoppingListIds?.length"
                      :style="mdUp ? 'top: 80px' : 'top:130px'"
                    >
                      <v-badge
                        bordered
                        color="red darken-1"
                        :content="shoppingListIds?.length"
                        :value="shoppingListIds?.length"
                        overlap
                      >
                        <v-btn color="grey" icon small @click="showShoppingListDialog">
                          <v-icon>mdi-cart</v-icon>
                        </v-btn>
                      </v-badge>
                    </div>
                    <v-row justify="center" v-if="smDown">
                      <div class="text-body-1 grey--text my-3">------END------</div>
                    </v-row>
                  </div>
                  <div
                    class="bg"
                    :style="{
                      backgroundColor: user?.brandColor ? user?.brandColor : '#97c81f',
                    }"
                  />
                  <div class="text-caption bg-text">Copyright 2023 ©</div>
                  <div class="social-medias d-flex justify-end" style="gap: 0.5rem">
                    <a v-if="user.facebook" :href="user.facebook" target="_blank">
                      <v-icon medium color="white"> mdi-facebook </v-icon>
                    </a>
                    <!-- <v-icon
                        medium
                        color="white"
                        v-else
                    >
                        mdi-facebook
                    </v-icon> -->
                    <a v-if="user.website" :href="user.website" target="_blank">
                      <v-icon medium color="white"> mdi-web </v-icon>
                    </a>
                    <!-- <v-icon
                        medium
                        color="white"
                        v-else
                    >
                        mdi-web
                    </v-icon> -->
                    <a v-if="user.instagram" :href="user.instagram" target="_blank">
                      <v-icon medium color="white"> mdi-instagram </v-icon>
                    </a>
                    <!-- <v-icon
                        medium
                        color="white"
                        v-else
                    >
                        mdi-instagram
                    </v-icon> -->
                  </div>
                  <div v-if="user?.isStudent" class="text-body2 red--text sample-text">
                    *WARNING: This eBook was created by a student
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-sheet>
        </v-carousel-item>
        <v-carousel-item v-else-if="recipe.name == 'info'">
          <v-sheet height="100%" :class="mdUp ? 'pa-4' : 'pa-0'">
            <v-row justify="center" align="center">
              <v-col sm="12" md="9" lg="6" xl="5">
                <v-card
                  class="px-3 custom-card"
                  :style="{ height: mdUp ? '830px' : '800px' }"
                >
                  <v-row justify="end" align="end">
                    <v-col lg="9" xl="9" cols="7" class="text-end mr-3">
                      <div
                        class="font-weight-bold"
                        :class="mdUp ? 'text-h4' : 'text-subtitle1'"
                        :style="{
                          color: user?.brandColor ? user?.brandColor : '#97c81f',
                        }"
                      >
                        Nutrition Info
                      </div>
                      <div class="text-caption">
                        A Meal Plan eBook by {{ user?.firstName }}
                      </div>
                    </v-col>
                    <v-col cols="4" lg="2" xl="2">
                      <v-img
                        :src="user?.customLogoUrl"
                        contain
                        height="70px"
                        width="110px"
                      >
                        <template v-slot:placeholder>
                          <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular
                              indeterminate
                              :color="user?.brandColor ? user.brandColor : '#97c81f'"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>
                  </v-row>
                  <div
                    class="py-3 mt-3"
                    style="overflow: hidden auto"
                    :style="{ height: mdUp ? '670px' : '580px' }"
                  >
                    <v-row class="px-6" justify="center">
                      <div>
                        <Pie
                          :chart-options="chartOptions"
                          :chart-data="chartData"
                          chart-id="pie-chart"
                          dataset-id-key="label"
                          :width="320"
                          :height="310"
                        />
                        <div class="text-caption d-block text-center">
                          (in percentage %)
                        </div>
                      </div>
                      <v-card outlined class="mt-5">
                        <v-simple-table class="bordered" dense>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th
                                  class="white--text"
                                  :style="{
                                    backgroundColor: user?.brandColor
                                      ? user?.brandColor
                                      : '#97c81f',
                                  }"
                                >
                                  Day
                                </th>
                                <th
                                  class="white--text"
                                  :style="{
                                    backgroundColor: user?.brandColor
                                      ? user?.brandColor
                                      : '#97c81f',
                                  }"
                                >
                                  Weight (g)
                                </th>
                                <th
                                  class="white--text"
                                  :style="{
                                    backgroundColor: user?.brandColor
                                      ? user?.brandColor
                                      : '#97c81f',
                                  }"
                                >
                                  Energy
                                  {{ user.energyUnit == "cal" ? "(Cal)" : "(kJ)" }}
                                </th>
                                <th
                                  class="white--text"
                                  :style="{
                                    backgroundColor: user?.brandColor
                                      ? user?.brandColor
                                      : '#97c81f',
                                  }"
                                >
                                  Protein (g)
                                </th>
                                <th
                                  class="white--text"
                                  :style="{
                                    backgroundColor: user?.brandColor
                                      ? user?.brandColor
                                      : '#97c81f',
                                  }"
                                >
                                  Fat (g)
                                </th>
                                <th
                                  class="white--text"
                                  :style="{
                                    backgroundColor: user?.brandColor
                                      ? user?.brandColor
                                      : '#97c81f',
                                  }"
                                >
                                  Carbs (g)
                                </th>
                                <th
                                  class="white--text"
                                  :style="{
                                    backgroundColor: user?.brandColor
                                      ? user?.brandColor
                                      : '#97c81f',
                                  }"
                                >
                                  Sugar (g)
                                </th>
                                <th
                                  class="white--text"
                                  :style="{
                                    backgroundColor: user?.brandColor
                                      ? user?.brandColor
                                      : '#97c81f',
                                  }"
                                >
                                  Fibre (g)
                                </th>
                                <th
                                  class="white--text"
                                  :style="{
                                    backgroundColor: user?.brandColor
                                      ? user?.brandColor
                                      : '#97c81f',
                                  }"
                                >
                                  Sodium (mg)
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(day, i) in [
                                  'Monday',
                                  'Tuesday',
                                  'Wednesday',
                                  'Thursday',
                                  'Friday',
                                  'Saturday',
                                  'Sunday',
                                  'Weekly Average',
                                ]"
                                :key="i"
                              >
                                <td
                                  :class="{
                                    'font-weight-bold': day == 'Weekly Average',
                                  }"
                                  class="white--text"
                                  :style="{
                                    backgroundColor: user?.brandColor
                                      ? user?.brandColor
                                      : '#97c81f',
                                  }"
                                >
                                  {{ day }}
                                </td>
                                <td
                                  width="10%"
                                  :class="{
                                    'font-weight-bold grey lighten-3': i == 7,
                                  }"
                                >
                                  {{
                                    getTotalNutrientValue(i + 1, "weight")
                                      | amount
                                      | numberFormat
                                  }}
                                </td>
                                <td
                                  v-if="user.energyUnit == 'cal'"
                                  width="10%"
                                  :class="{
                                    'font-weight-bold grey lighten-3': i == 7,
                                  }"
                                >
                                  {{
                                    getTotalNutrientValue(i + 1, "energy")
                                      | kjToCal
                                      | amount
                                      | numberFormat
                                  }}
                                </td>
                                <td
                                  v-else
                                  width="10%"
                                  :class="{
                                    'font-weight-bold grey lighten-3': i == 7,
                                  }"
                                >
                                  {{
                                    getTotalNutrientValue(i + 1, "energy")
                                      | amount
                                      | numberFormat
                                  }}
                                </td>
                                <td
                                  width="10%"
                                  :class="{
                                    'font-weight-bold grey lighten-3': i == 7,
                                  }"
                                >
                                  {{
                                    getTotalNutrientValue(i + 1, "protein")
                                      | amount
                                      | numberFormat
                                  }}
                                </td>
                                <td
                                  width="10%"
                                  :class="{
                                    'font-weight-bold grey lighten-3': i == 7,
                                  }"
                                >
                                  {{
                                    getTotalNutrientValue(i + 1, "fat")
                                      | amount
                                      | numberFormat
                                  }}
                                </td>
                                <td
                                  width="10%"
                                  :class="{
                                    'font-weight-bold grey lighten-3': i == 7,
                                  }"
                                >
                                  {{
                                    getTotalNutrientValue(i + 1, "carbohydrate")
                                      | amount
                                      | numberFormat
                                  }}g
                                </td>
                                <td
                                  width="10%"
                                  :class="{
                                    'font-weight-bold grey lighten-3': i == 7,
                                  }"
                                >
                                  {{
                                    getTotalNutrientValue(i + 1, "sugar")
                                      | amount
                                      | numberFormat
                                  }}g
                                </td>
                                <td
                                  width="10%"
                                  :class="{
                                    'font-weight-bold grey lighten-3': i == 7,
                                  }"
                                >
                                  {{
                                    getTotalNutrientValue(i + 1, "fibre")
                                      | amount
                                      | numberFormat
                                  }}g
                                </td>
                                <td
                                  width="10%"
                                  :class="{
                                    'font-weight-bold grey lighten-3': i == 7,
                                  }"
                                >
                                  {{
                                    getTotalNutrientValue(i + 1, "sodium")
                                      | amount
                                      | numberFormat
                                  }}mg
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-card>
                    </v-row>
                    <div
                      class="shoppingcart"
                      v-if="shoppingListIds?.length"
                      :style="mdUp ? 'top: 110px' : 'top:130px'"
                    >
                      <v-badge
                        bordered
                        color="red darken-1"
                        :content="shoppingListIds?.length"
                        :value="shoppingListIds?.length"
                        overlap
                      >
                        <v-btn color="grey" icon small @click="showShoppingListDialog">
                          <v-icon>mdi-cart</v-icon>
                        </v-btn>
                      </v-badge>
                    </div>
                  </div>
                  <div
                    class="bg"
                    :style="{
                      backgroundColor: user?.brandColor ? user?.brandColor : '#97c81f',
                    }"
                  />
                  <div class="text-caption bg-text">Copyright 2023 ©</div>
                  <div class="social-medias d-flex justify-end" style="gap: 0.5rem">
                    <a v-if="user.facebook" :href="user.facebook" target="_blank">
                      <v-icon medium color="white"> mdi-facebook </v-icon>
                    </a>
                    <!-- <v-icon
                        medium
                        color="white"
                        v-else
                    >
                        mdi-facebook
                    </v-icon> -->
                    <a v-if="user.website" :href="user.website" target="_blank">
                      <v-icon medium color="white"> mdi-web </v-icon>
                    </a>
                    <!-- <v-icon
                        medium
                        color="white"
                        v-else
                    >
                        mdi-web
                    </v-icon> -->
                    <a v-if="user.instagram" :href="user.instagram" target="_blank">
                      <v-icon medium color="white"> mdi-instagram </v-icon>
                    </a>
                    <!-- <v-icon
                        medium
                        color="white"
                        v-else
                    >
                        mdi-instagram
                    </v-icon> -->
                  </div>
                  <div v-if="user?.isStudent" class="text-body2 red--text sample-text">
                    *WARNING: This eBook was created by a student
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-sheet>
        </v-carousel-item>
        <v-carousel-item v-else-if="recipe.name == 'instructions'">
          <v-sheet height="100%" :class="mdUp ? 'pa-4' : 'pa-0'">
            <v-row justify="center" align="center">
              <v-col sm="12" md="9" lg="6" xl="5">
                <v-card
                  class="px-3 custom-card"
                  :style="{ height: mdUp ? '830px' : '800px' }"
                >
                  <v-row justify="end" align="end">
                    <v-col lg="9" xl="9" cols="7" class="text-end mr-3">
                      <div
                        class="font-weight-bold"
                        :class="mdUp ? 'text-h4' : 'text-subtitle1'"
                        :style="{
                          color: user?.brandColor ? user?.brandColor : '#97c81f',
                        }"
                      >
                        Instructions
                      </div>
                      <div class="text-caption">
                        A Meal Plan eBook by {{ user?.firstName }}
                      </div>
                    </v-col>
                    <v-col cols="4" lg="2" xl="2">
                      <v-img
                        :src="user?.customLogoUrl"
                        contain
                        height="70px"
                        width="110px"
                      >
                        <template v-slot:placeholder>
                          <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular
                              indeterminate
                              :color="user?.brandColor ? user.brandColor : '#97c81f'"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>
                  </v-row>
                  <div class="mt-3 px-8">
                    <v-divider />
                  </div>
                  <div
                    class="py-3 mt-3"
                    style="overflow: hidden auto"
                    :style="{ height: mdUp ? '670px' : '580px' }"
                  >
                    <v-row class="px-6" justify="center">
                      <div>
                        <div class="mt-6 text-header-6 text-center font-weight-bold">
                          How to use this Meal Plan Ebook
                        </div>
                      </div>
                      <div v-if="plan?.hideCustomization">
                        <ol>
                          <li>
                            Swipe across the screen or use the arrows to navigate to the
                            recipe index.
                          </li>

                          <li>
                            On the recipe index page, you will find a list of recipes
                            suited to your requirements. Tap on the recipe you wish to
                            view.
                          </li>
                          <li>
                            On the recipe page, scroll down for an in-depth view of the
                            recipe ingredients and method for preparation. You can also
                            download a PDF of the recipe to store for later use if you
                            choose.
                          </li>
                          <li>
                            If you like the recipe and would like to add it to a shopping
                            list, simply click 'ADD TO SHOPPING LIST'. On the recipe index
                            page, you can also click 'ADD ALL RECIPES' and all recipes
                            will be added to a shopping list. This will add recipes to an
                            editable shopping list. A shopping trolley icon will appear
                            with the number of recipes included in the trolley.
                          </li>
                          <li>
                            Once you have edited the shopping list (if required), simply
                            click the 'DOWNLOAD LIST' button to download a PDF of the
                            categorised shopping list.
                          </li>
                          <li>
                            You can navigate back to the recipe index at any time by
                            clicking on the 'TABLE OF CONTENTS' button on any recipe page.
                          </li>
                        </ol>
                      </div>
                      <div v-if="!plan?.hideCustomization">
                        <ol>
                          <li>
                            Swipe across the screen or use the arrows to navigate to the
                            recipe index.
                          </li>

                          <li>
                            On the recipe index page, you will find a list of recipes
                            suited to your requirements. Tap on the recipe you wish to
                            view.
                          </li>
                          <li>
                            On the recipe page, scroll down for an in-depth view of the
                            recipe ingredients and method for preparation. You can also
                            download a PDF of the recipe to store for later use if you
                            choose.
                          </li>
                          <li>
                            If there is an ingredient you wish to change, simply click
                            ‘CUSTOMIZE'. You can delete unwanted ingredients or add
                            preferred ingredients. Your shopping list will be updated.
                          </li>
                          <li>
                            If you like the recipe and would like to add it to a shopping
                            list, simply click 'ADD TO SHOPPING LIST'. On the recipe index
                            page, you can also click 'ADD ALL RECIPES' and all recipes
                            will be added to a shopping list. This will add recipes to an
                            editable shopping list. A shopping trolley icon will appear
                            with the number of recipes included in the trolley.
                          </li>
                          <li>
                            Once you have edited the shopping list (if required), simply
                            click the 'DOWNLOAD LIST' button to download a PDF of the
                            categorised shopping list.
                          </li>
                          <li>
                            You can navigate back to the recipe index at any time by
                            clicking on the 'TABLE OF CONTENTS' button on any recipe page.
                          </li>
                        </ol>
                      </div>
                      <div>
                        <div class="mt-6 text-header-6 text-center font-weight-bold">
                          ENJOY!
                        </div>
                      </div>
                    </v-row>
                  </div>
                  <div
                    class="bg"
                    :style="{
                      backgroundColor: user?.brandColor ? user?.brandColor : '#97c81f',
                    }"
                  />
                  <div class="text-caption bg-text">Copyright 2023 ©</div>
                  <div class="social-medias d-flex justify-end" style="gap: 0.5rem">
                    <a v-if="user.facebook" :href="user.facebook" target="_blank">
                      <v-icon medium color="white"> mdi-facebook </v-icon>
                    </a>
                    <!-- <v-icon
                        medium
                        color="white"
                        v-else
                    >
                        mdi-facebook
                    </v-icon> -->
                    <a v-if="user.website" :href="user.website" target="_blank">
                      <v-icon medium color="white"> mdi-web </v-icon>
                    </a>
                    <!-- <v-icon
                        medium
                        color="white"
                        v-else
                    >
                        mdi-web
                    </v-icon> -->
                    <a v-if="user.instagram" :href="user.instagram" target="_blank">
                      <v-icon medium color="white"> mdi-instagram </v-icon>
                    </a>
                    <!-- <v-icon
                        medium
                        color="white"
                        v-else
                    >
                        mdi-instagram
                    </v-icon> -->
                  </div>
                  <div v-if="user?.isStudent" class="text-body2 red--text sample-text">
                    *WARNING: This eBook was created by a student
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-sheet>
        </v-carousel-item>
        <v-carousel-item v-else>
          <v-sheet height="100%" :class="mdUp ? 'pa-4' : 'pa-0 pt-2'">
            <v-row justify="center" align="center">
              <v-col sm="12" md="9" lg="6" xl="6">
                <v-card
                  class="px-3 custom-card"
                  :style="{ height: mdUp ? '830px' : '800px' }"
                >
                  <v-row justify="end" :align="mdUp ? 'end' : 'start'">
                    <v-col
                      lg="9"
                      xl="9"
                      cols="7"
                      class="mr-3"
                      :class="mdUp ? 'text-end' : 'text-center'"
                    >
                      <div
                        class="font-weight-bold"
                        :class="mdUp ? 'text-h4' : 'text-subtitle-1'"
                        :style="{
                          color: user?.brandColor ? user?.brandColor : '#97c81f',
                        }"
                      >
                        {{ upperFirst(recipe.name) }}
                      </div>
                      <div class="text-body-2" v-if="recipe?.mealTime?.length">
                        <v-icon
                          v-if="recipe?.mealTime[0]?.toLowerCase() == 'breakfast'"
                          color="brown"
                          small
                          >mdi-coffee</v-icon
                        >
                        <v-icon
                          v-else-if="recipe?.mealTime[0]?.toLowerCase() == 'lunch'"
                          color="yellow darken-3"
                          small
                          >mdi-food</v-icon
                        >
                        <v-icon
                          v-else-if="recipe?.mealTime[0]?.toLowerCase() == 'dinner'"
                          color="brown"
                          small
                          >mdi-food-turkey</v-icon
                        >
                        <v-icon
                          v-else-if="recipe?.mealTime[0]?.toLowerCase() == 'desert'"
                          color="brown"
                          small
                          >mdi-food-hamburger</v-icon
                        >
                        <v-icon v-else color="green darken-3" x-small
                          >mdi-food-apple</v-icon
                        >
                        {{ upperFirst(recipe.meal.time) }}
                      </div>
                      <div class="mt-2" v-if="mdUp">
                        <v-row justify="space-between" align="center" class="py-2">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                text
                                :color="user.brandColor"
                                v-on="on"
                                x-small
                                @click="goBack(i)"
                              >
                                <v-icon small>mdi-format-list-bulleted</v-icon>
                                <span>Table of Contents</span>
                              </v-btn>
                            </template>
                            <span>Back to Table of Contents</span>
                          </v-tooltip>
                          <div>
                            <!-- <v-btn
                              small
                              :color="user.brandColor"
                              depressed
                              class="white--text mr-2"
                              @click="setRecipe(recipe)"
                              >View Recipe Card</v-btn
                            > -->
                            <v-btn
                              v-if="!shoppingListIds.includes(recipe.id)"
                              small
                              :color="user.brandColor"
                              depressed
                              class="white--text"
                              @click="addToShoppingList(recipe)"
                              >Add to Shopping list</v-btn
                            >
                            <v-btn
                              v-if="shoppingListIds.includes(recipe.id)"
                              small
                              color="warning"
                              depressed
                              class="white--text"
                              @click="removeFromShoppingList(recipe.id)"
                              >Remove from Shopping list</v-btn
                            >
                          </div>
                        </v-row>
                      </div>
                      <div class="mt-2" v-else>
                        <!-- <v-btn
                          x-small
                          :color="user.brandColor"
                          depressed
                          class="white--text mr-2"
                          @click="setRecipe(recipe)"
                          >View Recipe Card</v-btn
                        > -->
                        <v-btn
                          v-if="!shoppingListIds.includes(recipe.id)"
                          x-small
                          :color="user.brandColor"
                          depressed
                          class="white--text"
                          @click="addToShoppingList(recipe)"
                          >Add to Shopping list</v-btn
                        >
                        <v-btn
                          v-if="shoppingListIds.includes(recipe.id)"
                          x-small
                          color="warning"
                          depressed
                          class="white--text"
                          @click="removeFromShoppingList(recipe.id)"
                          >Remove from Shopping list</v-btn
                        >
                      </div>
                    </v-col>
                    <v-col lg="2" xl="2" cols="4">
                      <v-img :src="user?.customLogoUrl" contain>
                        <template v-slot:placeholder>
                          <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular
                              indeterminate
                              :color="user?.brandColor ? user.brandColor : '#97c81f'"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>
                  </v-row>
                  <v-row v-if="smDown" justify="center" align="center" class="mt-3 mb-0">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          text
                          :color="user.brandColor"
                          v-on="on"
                          x-small
                          @click="goBack(i)"
                        >
                          <v-icon small>mdi-format-list-bulleted</v-icon>
                          <span>Table of Contents</span>
                        </v-btn>
                      </template>
                      <span>Back to Table of Contents</span>
                    </v-tooltip>
                  </v-row>
                  <div class="mb-3 px-8" :class="mdUp ? 'mt-3' : 'mt-1'">
                    <v-divider />
                  </div>
                  <v-row justify="center" class="py-3">
                    <v-col lg="4" xl="4" cols="12">
                      <!-- <v-img
                        v-if="recipe.OriginalPhotoUrl"
                        :src="recipe.OriginalPhotoUrl"
                        height="250px"
                        :width="mdUp ? '250px' : '100%'"
                        contain
                      >
                        <template v-slot:placeholder>
                          <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular
                              indeterminate
                              :color="user?.brandColor ? user.brandColor : '#97c81f'"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img> -->
                      <recipe-image :recipe="recipe" size="medium" :updateFields="false" />
                      <!-- <v-img
                        v-else
                        :src="recipe.OriginalPhotoUrl"
                        width="250px"
                        height="250px"
                        contain
                      >
                        <template v-slot:placeholder>
                          <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular
                              indeterminate
                              :color="user?.brandColor ? user.brandColor : '#97c81f'"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img> -->
                    </v-col>
                    <v-col lg="7" xl="7" cols="12">
                      <div
                        class="py-3"
                        style="overflow: hidden auto"
                        :style="{ height: mdUp ? '385px' : '370px' }"
                      >
                        <div class="d-flex gap-5 justify-start align-center mb-3">
                          <v-btn
                            v-if="!plan?.hideCustomization"
                            x-small
                            class="white--text"
                            :color="user.brandColor"
                            @click="setCustomRecData(recipe)"
                            :disabled="recipe.meal?.mealPrep"
                            depressed
                          >
                            <v-icon x-small left>mdi-pencil</v-icon>
                            Customize
                          </v-btn>
                          <v-btn
                            @click="startDownload(recipe)"
                            :loading="downloading"
                            :color="user?.brandColor"
                            class="mr-2 white--text"
                            depressed
                            x-small
                          >
                            <v-icon left>mdi-printer</v-icon>
                            Print PDF
                          </v-btn>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                v-on="on"
                                @click="resetRecipe(recipe)"
                                color="warning"
                                class="white--text"
                                depressed
                                :disabled="!hasCustomIng(recipe)"
                                :loading="mealplanStatus.updatingMeal"
                                icon
                                x-small
                              >
                              <v-icon>mdi-refresh</v-icon>
                              </v-btn>
                            </template>
                            <span>Reset to default ingredients</span>
                          </v-tooltip>
                        </div>
                        <span v-if="recipe.lock_serve">
                          <v-alert dense text type="warning">
                            <strong>WARNING</strong>: Original serve quantities shown only
                            due to ingredients that don't divide well
                          </v-alert>
                          <p class="text-caption mb-5 font-weight-bold">
                            Makes {{ recipe.numberOfServes }} Serves ({{
                              recipe.servingValues.weight | numberFormat
                            }}g per serve)
                          </p>
                        </span>
                        <span v-else-if="hasCustomServes(recipe.id)">
                          <p class="text-caption mb-5 font-weight-bold">
                            <span
                              ><v-btn
                                icon
                                x-small
                                depressed
                                @click="setCustomServeData(recipe)"
                                ><v-icon x-small>mdi-pencil</v-icon></v-btn
                              ></span
                            >
                            Makes {{ recipe.customServe }} Serves ({{
                              recipe.servingValues.weight | numberFormat
                            }}g per serve)
                          </p>
                          
                        </span>
                        <!-- <p v-else-if="recipe.servingValues" class="mb-5 font-weight-bold text-caption">
                          Makes {{ serves || recipe.numberOfServes }} Serves ({{
                            recipe.servingValues.weight | numberFormat
                          }}g per serve)
                        </p> -->
                        <p
                          v-else-if="recipe?.mealTime?.length && !recipe.lock_serve"
                          class="text-caption mb-5 font-weight-bold"
                        >
                          <span
                            ><v-btn
                              icon
                              x-small
                              depressed
                              @click="setCustomServeData(recipe)"
                              ><v-icon x-small>mdi-pencil</v-icon></v-btn
                            ></span
                          >
                          Makes {{  recipe.meal?.serves }} Serves ({{
                            recipe.servingValues.weight | numberFormat
                          }}g per serve)
                        </p>
                        <div v-if="recipe.meal?.mealPrep">
                          <v-alert dense text type="warning">
                            <strong>NOTICE</strong>: 
                            This meal has been meal prepped
                          </v-alert>
                        </div>
                        <div>
                          <v-card
                            v-if="recipe.ingredients && recipe.ingredients?.length"
                            class="mb-5"
                            flat
                          >
                            <v-simple-table class="grey lighten-4" bordered dense>
                              <template v-slot:default>
                                <thead>
                                  <tr>
                                    <th
                                      class="mb-5 subtitle-1 font-weight-bold"
                                      :style="{
                                        color: user?.brandColor
                                          ? user?.brandColor
                                          : '#97c81f',
                                      }"
                                    >
                                      Ingredients
                                    </th>
                                    <th>Serve Amount</th>
                                    <th>Serve Type</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="recipeIngredient in orderBy(
                                      recipe.ingredients,
                                      'order',
                                      'asc'
                                    )"
                                    :key="recipeIngredient.id"
                                  >
                                    <td class="text-capitalize py-2" width="40%">
                                      {{ recipeIngredient.food }}
                                    </td>
                                    <td v-if="recipe.lock_serve" width="30%">
                                      {{ parseFloat(recipeIngredient.quantity) }}
                                    </td>
                                    <td v-else-if="hasCustomServes(recipe.id)" width="30%">
                                      {{
                                        parseFloat(
                                          (
                                            recipeIngredient.quantity *
                                          ((recipe?.customServe ? recipe?.customServe : recipe.meal.serves) || recipe.numberOfServes) /
                                          recipe.numberOfServes
                                          ).toFixed(2)
                                        )
                                      }}
                                    </td>
                                    <td v-else-if="mealPrep">0</td>
                                    <td v-else width="30%">
                                      {{
                                        parseFloat(
                                          (
                                            recipeIngredient.quantity *
                                            recipe.meal.serves /
                                            recipe.numberOfServes
                                          ).toFixed(2)
                                        )
                                      }}
                                    </td>
                                    <td width="30%">
                                      {{ recipeIngredient.portion }}
                                    </td>
                                  </tr>
                                </tbody>
                              </template>
                            </v-simple-table>
                          </v-card>
                        </div>
                        <div
                          v-if="recipe.mealTime && recipe.mealTime?.length"
                          class="mb-5"
                        >
                          <h5
                            class="mb-2 subtitle-1 font-weight-bold"
                            :style="{
                              color: user?.brandColor ? user?.brandColor : '#97c81f',
                            }"
                          >
                            Meal Time
                          </h5>

                          <p>{{ recipe.mealTime.join(", ") }}</p>
                        </div>

                        <!-- VIDEO -->

                        <div v-if="recipe.video" class="mb-5">
                          <h5
                            class="mb-2 subtitle-1 font-weight-bold"
                            :style="{
                              color: user?.brandColor ? user?.brandColor : '#97c81f',
                            }"
                          >
                            Video
                          </h5>

                          <a :href="recipe.video" target="_blank">{{ recipe.video }}</a>
                        </div>

                        <!-- DESCRIPTION -->
                        <div v-if="recipe.description" class="mb-5">
                          <h5
                            class="mb-2 subtitle-1 font-weight-bold"
                            :style="{
                              color: user?.brandColor ? user?.brandColor : '#97c81f',
                            }"
                          >
                            Description
                          </h5>

                          <p class="pre-wrap" v-html="linkify(recipe.description)"></p>
                        </div>

                        <!-- METHODS -->
                        <div v-if="recipe.methods && recipe.methods?.length" class="mb-5">
                          <h5
                            class="mb-2 subtitle-1 font-weight-bold"
                            :style="{
                              color: user?.brandColor ? user?.brandColor : '#97c81f',
                            }"
                          >
                            Methods
                          </h5>

                          <ol>
                            <li
                              v-for="(method, i) in recipe.methods"
                              :key="i"
                              class="mb-1 pre-wrap"
                            >
                              {{ method }}
                            </li>
                          </ol>
                        </div>

                        <div v-if="!plan?.hideMealTags" class="mt-5">
                          <div
                            class="font-weight-bold mb-2"
                            :style="{
                              color: user?.brandColor ? user?.brandColor : '#97c81f',
                            }"
                          >
                            Meal Tags
                          </div>

                          <span v-for="(tag, i) in recipe.tags" :key="i">
                            <v-chip
                              label
                              small
                              class="mr-1 mb-1"
                              v-if="$store.getters['tags/checkIfUnpublish'](tag)"
                              >{{ $store.getters["tags/getName"](tag) }}</v-chip
                            >
                          </span>
                        </div>

                        <div class="mt-3" v-if="!plan?.hideNutriTags">
                          <div
                            class="font-weight-bold mb-2"
                            :style="{
                              color: user?.brandColor ? user?.brandColor : '#97c81f',
                            }"
                          >
                            Nutrition Tags
                          </div>

                          <span v-for="(tag, i) in recipe.nutritionTags" :key="i">
                            <v-chip
                              label
                              small
                              class="mr-1 mb-1"
                              v-if="$store.getters['nutritiontags/checkIfUnpublish'](tag)"
                              >{{
                                $store.getters["nutritiontags/tagData"](tag).name
                              }}</v-chip
                            >
                          </span>
                        </div>

                        <!-- ANALYSIS SUMMARY -->
                        <div v-if="!plan?.hideEnergy">
                          <h5
                            class="mb-2 subtitle-1 font-weight-bold"
                            :style="{
                              color: user?.brandColor ? user?.brandColor : '#97c81f',
                            }"
                          >
                            Analysis Summary
                          </h5>

                          <v-card flat>
                            <v-simple-table
                              class="grey lighten-4"
                              id="nutrient-table"
                              dense
                            >
                              <template v-slot:default>
                                <thead>
                                  <tr>
                                    <th width="33%"></th>
                                    <th width="33%">Quantity Per Serving</th>
                                    <th width="33%">Quantity Per 100g</th>
                                  </tr>
                                </thead>
                                <tbody v-if="recipe.servingValues">
                                  <tr>
                                    <td>Weight (g)</td>
                                    <td>
                                      {{
                                        recipe.servingValues.weight
                                          | amount
                                          | numberFormat
                                      }}g
                                    </td>
                                    <td>100.00g</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      Energy
                                      {{ user.energyUnit == "cal" ? "(Cal)" : "(kJ)" }}
                                    </td>
                                    <td v-if="user.energyUnit == 'cal'">
                                      {{
                                        recipe.servingValues.energy
                                          | kjToCal
                                          | amount
                                          | numberFormat
                                      }}Cal
                                    </td>
                                    <td v-else>
                                      {{
                                        recipe.servingValues.energy
                                          | amount
                                          | numberFormat
                                      }}kJ
                                    </td>
                                    <td v-if="user.energyUnit == 'cal'">
                                      {{
                                        recipe.servingValuesPerHundredGram.energy
                                          | kjToCal
                                      }}Cal
                                    </td>
                                    <td v-else>
                                      {{ recipe.servingValuesPerHundredGram.energy }}kJ
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Protein (g)</td>
                                    <td>
                                      {{
                                        recipe.servingValues.protein
                                          | amount
                                          | numberFormat
                                      }}g
                                    </td>
                                    <td>
                                      {{ recipe.servingValuesPerHundredGram.protein }}g
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Fat (g)</td>
                                    <td>
                                      {{
                                        recipe.servingValues.fat | amount | numberFormat
                                      }}g
                                    </td>
                                    <td>{{ recipe.servingValuesPerHundredGram.fat }}g</td>
                                  </tr>
                                  <tr>
                                    <td>Carbohydrate (g)</td>
                                    <td>
                                      {{
                                        recipe.servingValues.carbohydrate
                                          | amount
                                          | numberFormat
                                      }}g
                                    </td>
                                    <td>
                                      {{
                                        recipe.servingValuesPerHundredGram.carbohydrate
                                      }}g
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Sugar (g)</td>
                                    <td>
                                      {{
                                        recipe.servingValues.sugar
                                          | amount
                                          | numberFormat
                                      }}g
                                    </td>
                                    <td>
                                      {{ recipe.servingValuesPerHundredGram.sugar }}g
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Fibre (g)</td>
                                    <td>
                                      {{
                                        recipe.servingValues.fibre
                                          | amount
                                          | numberFormat
                                      }}g
                                    </td>
                                    <td>
                                      {{ recipe.servingValuesPerHundredGram.fibre }}g
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Sodium (mg)</td>
                                    <td>
                                      {{
                                        recipe.servingValues.sodium
                                          | amount
                                          | numberFormat
                                      }}mg
                                    </td>
                                    <td>
                                      {{ recipe.servingValuesPerHundredGram.sodium }}mg
                                    </td>
                                  </tr>
                                </tbody>
                              </template>
                            </v-simple-table>
                          </v-card>
                        </div>
                        <p v-if="mealPrep">
                          This meal has been meal prepped. Click
                          <a @click="showOriginalRec" class="text-decoration-underline"
                            >here</a
                          >
                          to see the original recipe
                        </p>
                      </div>
                    </v-col>
                  </v-row>
                  <div
                    class="shoppingcart"
                    v-if="shoppingListIds?.length"
                    :style="mdUp ? 'top: 140px' : 'top:130px'"
                  >
                    <v-badge
                      bordered
                      color="red darken-1"
                      :content="shoppingListIds?.length"
                      :value="shoppingListIds?.length"
                      overlap
                    >
                      <v-btn color="grey" icon small @click="showShoppingListDialog">
                        <v-icon>mdi-cart</v-icon>
                      </v-btn>
                    </v-badge>
                  </div>
                  <div
                    class="bg"
                    :style="{
                      backgroundColor: user?.brandColor ? user?.brandColor : '#97c81f',
                    }"
                  />
                  <div class="text-caption bg-text">Copyright 2023 ©</div>
                  <div class="social-medias d-flex justify-end" style="gap: 0.5rem">
                    <a v-if="user.facebook" :href="user.facebook" target="_blank">
                      <v-icon medium color="white"> mdi-facebook </v-icon>
                    </a>
                    <!-- <v-icon
                        medium
                        color="white"
                        v-else
                    >
                        mdi-facebook
                    </v-icon> -->
                    <a v-if="user.website" :href="user.website" target="_blank">
                      <v-icon medium color="white"> mdi-web </v-icon>
                    </a>
                    <!-- <v-icon
                        medium
                        color="white"
                        v-else
                    >
                        mdi-web
                    </v-icon> -->
                    <a v-if="user.instagram" :href="user.instagram" target="_blank">
                      <v-icon medium color="white"> mdi-instagram </v-icon>
                    </a>
                    <!-- <v-icon
                        medium
                        color="white"
                        v-else
                    >
                        mdi-instagram
                    </v-icon> -->
                  </div>
                  <div v-if="user?.isStudent" class="text-body2 red--text sample-text">
                    *WARNING: This eBook was created by a student
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-sheet>
        </v-carousel-item>
      </div>
    </v-carousel>

    <!-- RECIPE CARD DIALOG -->
    <v-dialog
      class="overflow-visible"
      no-click-animation
      max-width="400"
      :value="show"
      persistent
      scrollable
    >
      <v-card v-if="recipe">
        <v-card-title>
          <span class="text-h6 font-weight-bold headline">{{ recipe.name }}</span>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <div class="d-flex justify-center align-center flex-wrap mt-3">
            <div class="col-12 pa-0 text-center">
              <div class="text-subtitle1">Downloading your PDF..</div>
            </div>
            <div class="col-12 text-center">
              <v-progress-circular indeterminate size="34"></v-progress-circular>
            </div>
          </div>
        </v-card-text>
        <v-card-text class="pt-5 pb-7" style="display: none">
          <v-row>
            <v-col cols="12" md="7" lg="7" xl="7">
              <!-- INGREDIENTS -->

              <v-card
                v-if="recipe.ingredients && recipe.ingredients?.length"
                class="mb-5"
                flat
              >
                <v-simple-table class="grey lighten-4" bordered dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th
                          class="mb-5 subtitle-1 font-weight-bold"
                          :style="{
                            color: user?.brandColor ? user?.brandColor : '#97c81f',
                          }"
                        >
                          Ingredients
                        </th>
                        <th>Serve Amount</th>
                        <th>Serve Type</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="recipeIngredient in orderBy(
                          recipe.ingredients,
                          'order',
                          'asc'
                        )"
                        :key="recipeIngredient.id"
                      >
                        <td class="text-capitalize py-2" width="40%">
                          {{ recipeIngredient.food }}
                        </td>
                        <td v-if="recipe.lock_serve" width="30%">
                          {{ parseFloat(recipeIngredient.quantity) }}
                        </td>
                        <td width="30%">
                          {{
                            parseFloat(
                              (
                                recipeIngredient.quantity *
                                ((serves || recipe.numberOfServes) /
                                  recipe.numberOfServes)
                              ).toFixed(2)
                            )
                          }}
                        </td>
                        <td width="30%">{{ recipeIngredient.portion }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>

              <span v-if="recipe.lock_serve">
                <v-alert dense text type="warning">
                  <strong>WARNING</strong>: Original serve quantities shown only due to
                  ingredients that don't divide well
                </v-alert>
                <p class="mb-5 font-weight-bold">
                  Makes {{ recipe.numberOfServes }} Serves ({{
                    recipe.servingValues.weight | numberFormat
                  }}g per serve)
                </p>
              </span>
              <p v-else-if="recipe.servingValues" class="mb-5 font-weight-bold">
                Makes {{ serves || recipe.numberOfServes }} Serves ({{
                  recipe.servingValues.weight | numberFormat
                }}g per serve)
              </p>

              <!-- MEAL TIME -->
              <div v-if="recipe.mealTime && recipe.mealTime?.length" class="mb-5">
                <h5
                  class="mb-2 subtitle-1 font-weight-bold"
                  :style="{
                    color: user?.brandColor ? user?.brandColor : '#97c81f',
                  }"
                >
                  Meal Time
                </h5>

                <p>{{ recipe.mealTime.join(", ") }}</p>
              </div>

              <!-- VIDEO -->

              <div v-if="recipe.video" class="mb-5">
                <h5
                  class="mb-2 subtitle-1 font-weight-bold"
                  :style="{
                    color: user?.brandColor ? user?.brandColor : '#97c81f',
                  }"
                >
                  Video
                </h5>

                <a :href="recipe.video" target="_blank">{{ recipe.video }}</a>
              </div>

              <!-- DESCRIPTION -->
              <div v-if="recipe.description" class="mb-5">
                <h5
                  class="mb-2 subtitle-1 font-weight-bold"
                  :style="{
                    color: user?.brandColor ? user?.brandColor : '#97c81f',
                  }"
                >
                  Description
                </h5>

                <p class="pre-wrap" v-html="linkify(recipe.description)"></p>
              </div>

              <!-- METHODS -->
              <div v-if="recipe.methods && recipe.methods?.length" class="mb-5">
                <h5
                  class="mb-2 subtitle-1 font-weight-bold"
                  :style="{
                    color: user?.brandColor ? user?.brandColor : '#97c81f',
                  }"
                >
                  Methods
                </h5>

                <ol>
                  <li
                    v-for="(method, i) in recipe.methods"
                    :key="i"
                    class="mb-1 pre-wrap"
                  >
                    {{ method }}
                  </li>
                </ol>
              </div>

              <!-- ANALYSIS SUMMARY -->
              <div v-if="!plan?.hideEnergy">
                <h5
                  class="mb-2 subtitle-1 font-weight-bold"
                  :style="{
                    color: user?.brandColor ? user?.brandColor : '#97c81f',
                  }"
                >
                  Analysis Summary
                </h5>

                <v-card flat>
                  <v-simple-table class="grey lighten-4" id="nutrient-table" dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th width="33%"></th>
                          <th width="33%">Quantity Per Serving</th>
                          <th width="33%">Quantity Per 100g</th>
                        </tr>
                      </thead>
                      <tbody v-if="recipe.servingValues">
                        <tr>
                          <td>Weight (g)</td>
                          <td>
                            {{ recipe.servingValues.weight | amount | numberFormat }}g
                          </td>
                          <td>100.00g</td>
                        </tr>
                        <tr>
                          <td>
                            Energy
                            {{ user.energyUnit == "cal" ? "(Cal)" : "(kJ)" }}
                          </td>
                          <td v-if="user.energyUnit == 'cal'">
                            {{
                              recipe.servingValues.energy
                                | kjToCal
                                | amount
                                | numberFormat
                            }}Cal
                          </td>
                          <td v-else>
                            {{ recipe.servingValues.energy | amount | numberFormat }}kJ
                          </td>
                          <td v-if="user.energyUnit == 'cal'">
                            {{ recipe.servingValuesPerHundredGram.energy | kjToCal }}Cal
                          </td>
                          <td v-else>
                            {{ recipe.servingValuesPerHundredGram.energy }}kJ
                          </td>
                        </tr>
                        <tr>
                          <td>Protein (g)</td>
                          <td>
                            {{ recipe.servingValues.protein | amount | numberFormat }}g
                          </td>
                          <td>{{ recipe.servingValuesPerHundredGram.protein }}g</td>
                        </tr>
                        <tr>
                          <td>Fat (g)</td>
                          <td>{{ recipe.servingValues.fat | amount | numberFormat }}g</td>
                          <td>{{ recipe.servingValuesPerHundredGram.fat }}g</td>
                        </tr>
                        <tr>
                          <td>Carbohydrate (g)</td>
                          <td>
                            {{
                              recipe.servingValues.carbohydrate | amount | numberFormat
                            }}g
                          </td>
                          <td>{{ recipe.servingValuesPerHundredGram.carbohydrate }}g</td>
                        </tr>
                        <tr>
                          <td>Sugar (g)</td>
                          <td>
                            {{ recipe.servingValues.sugar | amount | numberFormat }}g
                          </td>
                          <td>{{ recipe.servingValuesPerHundredGram.sugar }}g</td>
                        </tr>
                        <tr>
                          <td>Fibre (g)</td>
                          <td>
                            {{ recipe.servingValues.fibre | amount | numberFormat }}g
                          </td>
                          <td>{{ recipe.servingValuesPerHundredGram.fibre }}g</td>
                        </tr>
                        <tr>
                          <td>Sodium (mg)</td>
                          <td>
                            {{ recipe.servingValues.sodium | amount | numberFormat }}mg
                          </td>
                          <td>{{ recipe.servingValuesPerHundredGram.sodium }}mg</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </div>
            </v-col>

            <v-col cols="12" md="5" lg="5" xl="5">
              <RecipeImage :recipe="recipe" @url="setImageUrl($event)" size="medium" />

              <!-- RECIPE RATING -->
              <!-- <recipe-rating :recipe="recipe" global /> -->

              <div v-if="!plan?.hideMealTags" class="mt-5">
                <div
                  class="font-weight-bold mb-2"
                  :style="{
                    color: user?.brandColor ? user?.brandColor : '#97c81f',
                  }"
                >
                  Meal Tags
                </div>

                <span v-for="(tag, i) in tagsConf" :key="i">
                  <v-chip
                    label
                    small
                    class="mr-1 mb-1"
                    v-if="$store.getters['tags/checkIfUnpublish'](tag)"
                    >{{ $store.getters["tags/getName"](tag) }}</v-chip
                  >
                </span>
              </div>

              <div class="mt-3" v-if="!plan?.hideNutriTags">
                <div
                  class="font-weight-bold mb-2"
                  :style="{
                    color: user?.brandColor ? user?.brandColor : '#97c81f',
                  }"
                >
                  Nutrition Tags
                </div>

                <span v-for="(tag, i) in recipe.nutritionTags" :key="i">
                  <v-chip
                    label
                    small
                    class="mr-1 mb-1"
                    v-if="$store.getters['nutritiontags/checkIfUnpublish'](tag)"
                    >{{ $store.getters["nutritiontags/tagData"](tag).name }}</v-chip
                  >
                </span>
              </div>

              <!-- <v-card class="my-5" flat color="grey lighten-4">
              <v-card-text>
                <div class="d-flex align-center">
                  <v-avatar class="mr-3" size="40">
                    <UserPhoto :id="recipe.user" :size="50" photoSize="thumb" />
                  </v-avatar>
                  <div>
                    <div class="font-weight-bold">{{ $store.getters['members/getUser'](recipe.user) }} </div>
                    <div class="caption">
                      Created {{ recipe.createdAt | formatDate }}
                    </div>
                  </div>
                </div>
              </v-card-text>
            </v-card> -->

              <!-- RATING FORM -->
              <!-- <rating-form :recipe="recipe" :rate="rate" class="mb-5" /> -->
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- CONFIRM DOWNLOAD -->
    <v-dialog v-model="confirmDownload" max-width="500">
      <close-button @click="confirmDownload = false" overflow />
      <v-card>
        <v-card-title primary-title> Please confirm </v-card-title>
        <v-card-text>
          <p v-if="parseInt(user?.downloadCredits) == -1">
            {{ user.firstName }} have unlimited download credits.
          </p>
          <p v-else-if="user?.downloadCredits <= 0">
            {{ user.firstName }} have any download credits left.
          </p>
          <p v-else>
            Download credits: {{ user?.downloadCredits }} <br />
            {{
              user?.downloadCredits == 1
                ? "This is the last download credit."
                : "This will use one of the download credits."
            }}
          </p>

          <p v-for="(status, i) in statuses" :key="i">
            {{ status }}
          </p>

          <!-- <v-btn
            v-if="user.downloadCredits == 0"
            :to="{ name: 'Subscription' }"
            :loading="downloading"
            color="primary"
            class="mr-2"
            >Buy More Credits</v-btn
          > -->

          <v-btn
            @click="downloadConfirmed()"
            :loading="downloading"
            color="primary"
            class="mr-2"
            small
            v-if="!user?.downloadCredits == 0"
            >Confirm Download</v-btn
          >

          <v-btn @click="confirmDownload = false" :disabled="downloading" text small
            >Cancel</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- DOWNLOADING DIALOG -->
    <v-dialog v-model="zipDialog" max-width="380" persistent>
      <v-card>
        <v-card-text class="pt-5">
          <div>
            <div class="subtitle-1">Please wait...</div>
            <v-progress-linear :value="downloadProgress" color="primary" height="25">
              <template v-slot:default>
                <strong>{{ downloadProgress }}%</strong>
              </template>
            </v-progress-linear>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- SHOPPING LIST DIALOG -->
    <shopping-list-dialog
      @dlShoppingList="dlShoppingList()"
      @openWarningDialog="openWarningDialog()"
      ref="shoppingList"
      :meals="unfilteredMeals"
      :recipes="[...shoppingList]"
      :user="user"
    />

    <!-- WARNING DIALOG -->
    <warning-dialog ref="warningDialog" />

    <!-- DOWNLOAD MEALPLAN -->
    <download-mealplan
      v-if="generatePdf"
      :user="user"
      :mainMeals="mainMeals"
      :meals="unfilteredMeals"
      :goal="goal"
      :rcps="recipes"
      :myRecipes="[]"
      :plan="plan"
      :name="plan.name"
      @downloading="downloadingPDF"
      @beforeDownload="beforeDownload"
      @downloadFinish="downloadPDFFinish"
    />
    <v-dialog max-width="380" v-model="isDeactivated" persistent>
      <v-card>
        <v-card-title>
          <v-icon left>mdi-alert</v-icon>
          <span class="font-weight-bold">NOTICE</span>
        </v-card-title>
        <v-card-text>
          <div>
            This eBook has been <strong>Deactivated by your Nutrion Professional</strong>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <FindIngredient ref="findIngredient" />

    <!-- CUSTOMIZE RECIPE DIALOG -->
    <v-dialog
      class="overflow-visible"
      no-click-animation
      max-width="920"
      v-model="customRecipeDialog"
      persistent
      scrollable
    >
      <close-button
        @click="closeCustomRecDialog()"
        overflow
      />
      <v-card>
        <v-card-title v-if="mdUp"
          >Customize the ingredient for "{{ customRecipeData?.name }}"</v-card-title
        >
        <div v-else class="text-subtitle2 text-center pa-3">
          {{ customRecipeData?.name }}
        </div>
        <div class="pa-2 px-6">
          <span v-if="customRecipeData.lock_serve">
            <v-alert dense text type="warning">
              <strong>WARNING</strong>: Original serve quantities shown only
              due to ingredients that don't divide well
            </v-alert>
            <p class="text-caption mb-5 font-weight-bold">
              Makes {{ customRecipeData.numberOfServes }} Serves ({{
                customRecipeData.servingValues.weight | numberFormat
              }}g per serve)
            </p>
          </span>
          <span v-else-if="hasCustomServes(customRecipeData.id)">
            <p class="text-caption mb-5 font-weight-bold">
              Makes {{ customRecipeData.customServe }} Serves ({{
                customRecipeData.servingValues.weight | numberFormat
              }}g per serve)
            </p>
          </span>
          <p
            v-else-if="customRecipeData?.mealTime?.length && !customRecipeData.lock_serve"
            class="text-caption mb-5 font-weight-bold"
          >
            Makes {{ customRecipeData.meal?.serves }} Serves ({{
              customRecipeData.servingValues.weight | numberFormat
            }}g per serve)
          </p>
        </div>
        <v-card-text class="pa-3 mb-3" v-if="mdUp">
          <v-simple-table class="grey lighten-4">
            <template v-slot:default>
              <thead>
                <tr>
                  <th width="80%">Ingredient</th>
                  <th width="10%">Remove</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(ing, i) in customRecipeIng" :key="i">
                  <td>
                    {{ ing.food }}
                  </td>
                  <td>
                    <v-btn @click="removeIngredient(i)" x-small color="error">
                      <v-icon x-small>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-text v-if="mdUp">
          <div class="text-subtitle1 mb-2">Add new ingredient</div>
          <!-- INGREDIENTS -->
          <div class="text-center px-2"
              v-show="mealplanStatus.updatingMeal"
          >
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>
          <v-card v-show="!mealplanStatus.updatingMeal" v-if="ingredients.length" class="mb-5" outlined>
            <v-simple-table> 
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Measure</th>
                    <th class="text-center">Quantity</th>
                    <th width="20px"></th>
                  </tr>
                </thead>
                <draggable v-model="draggableIngredients" tag="tbody">
                  <tr v-for="ingredient in draggableIngredients" :key="ingredient.id">
                    <td>
                      <div class="d-flex align-center">
                        <v-icon small class="mr-1">mdi-arrow-up-down-bold</v-icon>
                        <span>{{ ingredient.food.name }}</span>
                      </div>
                    </td>
                    <td>
                      <v-sheet max-width="250">
                        <v-select
                          v-model="ingredient.portion"
                          :items="ingredient.portions"
                          item-text="name"
                          item-value="id"
                          return-object
                          hide-details
                          outlined
                          dense
                        ></v-select>
                      </v-sheet>
                    </td>
                    <td>
                      <v-text-field
                        v-model="ingredient.quantity"
                        :style="{ width: '100px', margin: '0 auto' }"
                        type="number"
                        hide-details
                        outlined
                        min="0"
                        dense
                      ></v-text-field>
                    </td>
                    <td class="text-center">
                      <v-btn
                        @click="toggleDeleteIngredients(ingredient)"
                        :loading="status.deletingIngredient.includes(ingredient)"
                        icon
                        small
                      >
                        <v-icon small>mdi-minus-circle-outline</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </draggable>
              </template>
            </v-simple-table>
          </v-card>
          <!-- INSERT RECIPE FORM -->
          <v-form ref="insertRecipeForm">
            <div
              class="d-flex flex-column flex-md-row flex-lg-row flex-xl-row align-center gap-10"
            >
              <v-row dense>
                <v-col cols="12" md="4" lg="4" xl="4">
                  <v-text-field
                    v-model="newIngredient.food.name"
                    @click="showDialog()"
                    label="Ingredient Name"
                    hide-details
                    outlined
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4" lg="4" xl="4">
                  <v-select
                    @change="$store.commit('recipe/setPortion', $event)"
                    :loading="status.gettingPortions"
                    :disabled="status.gettingPortions"
                    :items="foodPortions"
                    label="Measurement"
                    item-text="name"
                    return-object
                    hide-details
                    outlined
                  ></v-select>
                </v-col>
                <v-col cols="12" md="4" lg="4" xl="4">
                  <v-text-field
                    v-model.number="newIngredient.quantity"
                    label="Quantity"
                    type="number"
                    hide-details
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-btn
                @click="insertRecipe()"
                :loading="status.addingIngredient"
                color="accent"
                depressed
                small
                fab
                data-intro="Add more ingredients as required"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </div>
          </v-form>
        </v-card-text>

        <v-expansion-panels accordion v-if="!mdUp" v-model="panel" :mandatory="true" flat>
          <v-expansion-panel>
            <v-expansion-panel-header>Ingredients</v-expansion-panel-header>
            <v-expansion-panel-content>
              <div style="overflow: hidden auto; height: 280px">
                <v-simple-table class="grey lighten-4">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th width="80%">Ingredient</th>
                        <th width="10%">Remove</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(ing, i) in customRecipeIng" :key="i">
                        <td>
                          {{ ing.food }}
                        </td>
                        <td>
                          <v-btn @click="removeIngredient(i)" x-small color="error">
                            <v-icon x-small>mdi-trash-can-outline</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-divider />
          <v-expansion-panel>
            <v-expansion-panel-header>Add New ingredient</v-expansion-panel-header>
            <v-expansion-panel-content>
              <!-- INGREDIENTS -->
              <v-card v-if="ingredients.length" class="mb-5" outlined>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Measure</th>
                        <th class="text-center">Quantity</th>
                        <th width="20px"></th>
                      </tr>
                    </thead>
                    <draggable v-model="draggableIngredients" tag="tbody">
                      <tr v-for="ingredient in draggableIngredients" :key="ingredient.id">
                        <td>
                          <div class="d-flex align-center">
                            <v-icon small class="mr-1">mdi-arrow-up-down-bold</v-icon>
                            <span>{{ ingredient.food.name }}</span>
                          </div>
                        </td>
                        <td>
                          <v-sheet max-width="250">
                            <v-select
                              v-model="ingredient.portion"
                              :items="ingredient.portions"
                              item-text="name"
                              item-value="id"
                              return-object
                              hide-details
                              outlined
                              dense
                            ></v-select>
                          </v-sheet>
                        </td>
                        <td>
                          <v-text-field
                            v-model="ingredient.quantity"
                            :style="{ width: '100px', margin: '0 auto' }"
                            type="number"
                            hide-details
                            outlined
                            min="0"
                            dense
                          ></v-text-field>
                        </td>
                        <td class="text-center">
                          <v-btn
                            @click="toggleDeleteIngredients(ingredient)"
                            :loading="status.deletingIngredient.includes(ingredient)"
                            icon
                            small
                          >
                            <v-icon small>mdi-minus-circle-outline</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </draggable>
                  </template>
                </v-simple-table>
              </v-card>
              <!-- INSERT RECIPE FORM -->
              <v-form ref="insertRecipeForm">
                <div
                  class="d-flex flex-column flex-md-row flex-lg-row flex-xl-row align-center gap-10"
                >
                  <v-row dense>
                    <v-col cols="12" md="4" lg="4" xl="4">
                      <v-text-field
                        v-model="newIngredient.food.name"
                        @click="showDialog()"
                        label="Ingredient Name"
                        hide-details
                        outlined
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" lg="4" xl="4">
                      <v-select
                        @change="$store.commit('recipe/setPortion', $event)"
                        :loading="status.gettingPortions"
                        :disabled="status.gettingPortions"
                        :items="foodPortions"
                        label="Measurement"
                        item-text="name"
                        return-object
                        hide-details
                        outlined
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="4" lg="4" xl="4">
                      <v-text-field
                        v-model.number="newIngredient.quantity"
                        label="Quantity"
                        type="number"
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-btn
                    @click="insertRecipe()"
                    :loading="status.addingIngredient"
                    color="accent"
                    depressed
                    small
                  >
                    add ingredient
                  </v-btn>
                </div>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-card-actions class="py-3">
          <v-btn
            :loading="mealplanStatus.updatingMeal"
            @click="customizeRecipe()"
            :color="user?.brandColor"
            class="white--text"
            depressed
          >
            Save Details
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- CUSTOMIZE SERVES DIALOG -->
    <v-dialog
      max-width="300"
      class="overflow-visible"
      v-model="customServesDialog"
      scrollable
      no-click-animation
    >
      <close-button
        @click="
          () => {
            customServesDialog = false;
            customRecipeServes = null;
          }
        "
        overflow
      />
      <v-card>
        <v-card-text class="pa-3 mb-3">
          <div class="text-subtitle1 mb-2">Adjust the serve quantity</div>
          <v-chip class="px-0" small>
            <v-btn
              @click="customRecipeServes = parseInt(customRecipeServes) - 1"
              :disabled="parseInt(customRecipeServes) < 2"
              icon
              x-small
            >
              <v-icon x-small>mdi-minus</v-icon>
            </v-btn>

            <span class="px-1">{{ customRecipeServes }}</span>

            <v-btn
              @click="customRecipeServes = parseInt(customRecipeServes) + 1"
              icon
              x-small
            >
              <v-icon x-small>mdi-plus</v-icon>
            </v-btn>
          </v-chip>
        </v-card-text>
        <v-card-actions class="py-3">
          <v-btn
            :loading="mealplanStatus.updatingMeal"
            @click="customizeServes()"
            :color="user?.brandColor"
            class="white--text"
            depressed
            small
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog max-width="380" v-model="showWarning" persistent>
      <v-card>
        <div v-if="!loadingWarning">
          <v-card-title>
            <v-icon left>mdi-alert</v-icon>
            <span class="font-weight-bold">Please Note</span>
          </v-card-title>
          <v-card-text>
            <div>
              This link is inactive. Please contact <a :href="`mailto:${user?.email}`">{{ user?.email }}</a> to discuss.
            </div>
          </v-card-text>
        </div>
        <div v-else>
           <v-card-text class="text-center">
            Loading, please wait..
            <v-progress-linear
              indeterminate
              color="grey"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import firebase from "firebase";
// PLUGINS
import JSZip from "jszip";
import { uniqBy, orderBy, upperFirst, groupBy, sortBy, sumBy, forOwn, intersection } from "lodash";
import { saveAs } from "file-saver";
import draggable from "vuedraggable";

import { mapActions, mapState, mapGetters } from "vuex";
import RecipeImage from "@/components/RecipeImage";
import ShoppingListDialog from "@/components/ShoppingListDialog";
import FindIngredient from "@/components/FindIngredientDialog";
import warningDialog from "./meals/components/WarningDialog.vue";
import "../css/quill.css";

import downloadRecipe from "@/mixins/download-recipe-pdf";

import { Pie } from "vue-chartjs/legacy";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import DownloadMealplan from "./meals/components/DownloadMealplan.vue";

let zip;

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale, ChartDataLabels);

export default {
  metaInfo: {
    title: "Meal Guide Pro",
  },

  name: "MealPlanEbook",

  /*------------------------------------------------------------------------------
   * MIXINS
   *----------------------------------------------------------------------------*/
  mixins: [downloadRecipe],

  data() {
    return {
      orderBy,
      serves: 1,
      panel: [0],
      user: null,
      goal: null,
      plan: null,
      upperFirst,
      error: null,
      recipes: [],
      meals: null,
      show: false,
      recipe: null,
      showBtn: false,
      mainMeals: null,
      shoppingList: [],
      filteredMeals: [],
      unfilteredMeals: [],
      downloading: false,
      shoppingListIds: [],
      mealShoppingListIds: [],
      // image: '/placeholder.svg',
      days: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
      settings: {
        dots: true,
        edgeFriction: 0.35,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        dotsClass: "slick-dots custom-dot-class",
      },
      slides: ["First", "Second", "Third", "Fourth", "Fifth"],
      navigateTo: [1, true],
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            color: "#fff",
          },
        },
      },
      statuses: [],
      generatePdf: false,
      confirmDownload: false,
      index: 0,
      zipDialog: false,
      image: null,
      mealPrep: false,
      origiData: null,
      customRecipeIng: [],
      customRecipeData: {},
      customRecipeServes: null,
      customRecipeDialog: false,
      customServesDialog: false,
      loadingWarning: true,
      unfilteredRecipes: [],
      tagLargeWeight: { mainAmount: 0 },
      tagSmallWeight: { mainAmount: 100000000 },
      customNutriTags: [],
      customMealTags: [],
      minusValue: 0,
      // downloadProgress: 0,
    };
  },
  components: {
    Pie,
    draggable,
    RecipeImage,
    warningDialog,
    DownloadMealplan,
    FindIngredient,
    ShoppingListDialog,
  },
  computed: {
    ...mapState({
      status: (state) => state.recipe.status,
      mealplanStatus: (state) => state.mealplans.status,
      ingredients: (state) => state.recipe.ingredients,
      foodPortions: (state) => state.recipe.foodPortions,
      newIngredient: (state) => state.recipe.newIngredient,
      ings: (state) => state.ingredients.ingredients,
      portions: (state) => state.ingredients.portions,
      mealTagsFromExcel: state => state.excel.mealTags,
    }),

    ...mapGetters('recipe', [
      'getTotalCarbohydrate',
      'getTotalProtein',
      'getTotalWeight',
      'getTotalEnergy',
      'getTotalSodium',
      'getTotalSugar',
      'getTotalFibre',
      'getTotalFat', 
    ]),

    allRecipes: function () {
      if (!this.plan) return [];

      const recipes = this.recipes;
      let arr = [
        { name: "cover", o: 1, id: 1, meal: { id: 1} },
        { name: "mealplans", o: 3, id: 3, meal: { id: 3 } },
      ];

      if (!this.plan?.hideInstructions) arr.push({ name: "instructions", o: 2, id: 2, meal: { id: 2 }});
      if (!this.plan?.hideEnergy) arr.push({ name: "info", o: 4, id: 4, meal: { id: 4} });

      arr = sortBy(arr, ["o"]);

      arr = [...arr, ...recipes];

      return arr;
    },

    valueToMinus() {
      let length = this.allRecipes.length - this.recipes.length
      switch(length) {
        case 4:
          return 3
        case 3: 
          return 2
        case 2:
          return 1
        default:
          return 0
      }
    },

    tagsConf: function () {
      if (this.recipe && this.recipe.tags) {
        return this.recipe.tags.filter((tag) => {
          return this.$store.getters["tags/getName"](tag);
        });
      } else return [];
    },
    mdUp: function () {
      return this.$vuetify.breakpoint.mdAndUp;
    },
    smDown: function () {
      return this.$vuetify.breakpoint.smAndDown;
    },

    groupedRecipes: function () {
      return groupBy(this.recipes, "meal.time");
    },
    groupByDate: function () {
      let grouped = {}
      let groupedMeals = groupBy(sortBy(this.filteredMeals, ["order"]), "day")

      forOwn(groupedMeals, function (value, key) {
        grouped[key] = uniqBy(value, (m) => m.order)
      })

      // return groupBy(sortBy(this.filteredMeals, ["order"]), "day");

      return grouped

    },
    headers: function () {
      return Object.keys(this.groupedRecipes);
    },

    chartData: function () {
      let f = Math.round(
        ((this.getTotalNutrientValue(8, "fat") * 37.7) /
          this.getTotalNutrientValue(8, "energy")) *
          100
      );
      let c = Math.round(
        ((this.getTotalNutrientValue(8, "carbohydrate") * 16.7) /
          this.getTotalNutrientValue(8, "energy")) *
          100
      );
      let p = Math.round(
        ((this.getTotalNutrientValue(8, "protein") * 16.7) /
          this.getTotalNutrientValue(8, "energy")) *
          100
      );

      return {
        labels: ["Fat %", "Carbs %", "Protein %"],
        datasets: [
          {
            borderWidth: 1,
            borderColor: [
              "rgba(238, 66, 66, 1)",
              "rgba(67, 97, 173, 1)",
              "rgba(148, 200, 85, 1)",
            ],
            backgroundColor: [
              "rgba(238, 66, 66, 1)",
              "rgba(67, 97, 173, 1)",
              "rgba(148, 200, 85, 1)",
            ],
            data: [f, c, p],
          },
        ],
      };
    },
    downloadProgress: function () {
      if (!this.meals?.length || !this.recipes?.length) return null;

      var meals = this.meals?.filter(
        (m) => m.recipe !== "no meal" && this.recipes.find((r) => r.id == m.recipe)
      )?.length;
      var progress = this.index / meals;
      return Math.round(progress * 100);
    },

    draggableIngredients: {
      get() {
        return orderBy(this.ingredients, "order", "asc");
      },

      set(values) {
        this.$store.commit("recipe/updateIngredientOrder", values);
      },
    },

    isDeactivated: function () {
      return this.plan?.isDeactivated || false;
    },

    showWarning() {
      return this.$store.getters['subscriptions/subscribed'] ? false : true
    },

    gramPerServe: function () {
      return Math.round(this.getTotalWeight)
    },

    minerals: function () {
      return [
        { name: 'Carbohydrate', value: this.getTotalCarbohydrate},
        { name: 'Protein', value: this.getTotalProtein},
        { name: 'Energy', value: this.getTotalEnergy}, 
        { name: 'Sodium', value: this.getTotalSodium},
        { name: 'Weight', value: this.getTotalWeight},
        { name: 'Sugar', value: this.getTotalSugar},
        { name: 'Fibre', value: this.getTotalFibre},
        { name: 'Fat', value: this.getTotalFat},
      ]
    },
    nutritionTags: function () {
      return this.$store.getters['nutritiontags/tags']
    },

    mealTags: function () {
      return this.$store.getters['tags/tags']
    },

  },
  methods: {
    ...mapActions("mealplans", ["getPlanToDownload", "addCustomizationData", "addCustomServesData"]),

    ...mapActions("recipe", ["deleteIngredient"]),

    ...mapActions("subscriptions", ["getPayments", "getSubscriptions"]),

    ...mapActions("user", ["getUserInfo"]),
    setImageUrl(e) {
      this.image = e;
    },

    setRecipe(recipe) {
      this.recipe = recipe;
      this.show = true;
    },

    linkify(text) {
      // Regular expression to match URLs
      const urlRegex = /(\b(?:https?|ftp):\/\/|www\.)\S+\b/gi;

      // Replace URLs with hyperlink tags
      return text.replace(urlRegex, function (url) {
        // Add http:// prefix if the URL doesn't have one
        if (!url.match(/^https?:\/\//i)) {
          url = "http://" + url;
        }
        return '<a href="' + url + '">' + url + "</a>";
      });
    },

    addAllMealsToShoppingList() {
      this.removeAllMealsFromShoppingList();
      this.unfilteredMeals.forEach((m) => this.addMealToShoppingList(m));
    },

    removeAllMealsFromShoppingList() {
      this.filteredMeals.forEach((m) => this.removeMealFromShoppingList(m));
    },

    addMealToShoppingList(meal) {
      let recipe = this.recipes.find((r) => r?.id == meal.recipe);

      if (!recipe) return;

      this.addToShoppingList(recipe);
      this.mealShoppingListIds.push(meal?.id);
    },

    removeMealFromShoppingList(meal) {
      let recipe = this.recipes.find((r) => r.id == meal.recipe);

      let index = this.shoppingList.findIndex((r) => r.id == recipe.id);

      let index1 = this.shoppingListIds.findIndex((r) => r == recipe.id);

      this.shoppingList.splice(index, 1);
      this.shoppingListIds.splice(index1, 1);

      this.mealShoppingListIds = this.mealShoppingListIds.filter((m) => m !== meal.id);
    },

    addToShoppingList(recipe) {
      console.log({ recipe })
      this.shoppingList.push(recipe);
      this.shoppingListIds.push(recipe.id);
    },

    removeFromShoppingList(id) {
      this.shoppingList = this.shoppingList.filter((s) => s.id !== id);
      this.shoppingListIds = this.shoppingListIds.filter((s) => s !== id);
    },
    showShoppingListDialog() {
      this.$refs.shoppingList.showDialog();
    },
    openWarningDialog() {
      this.$refs.warningDialog.openDialog();
    },

    // startDownload() {
    //   this.downloading = true;

    //   Promise.resolve(this.downloadPDF()).then(() => {
    //     this.downloading = false;
    //   });
    // },
    startDownload(recipe) {
      this.show = true;
      this.recipe = recipe;
      this.downloading = true;

      Promise.resolve(this.downloadPDF()).then(() => {
        this.downloading = false;
        this.show = false;
      });
    },

    addFixedDecimals(val) {
      if (val % 1 != 0) return this.$options.filters.amount(val);

      return val;
    },

    goTo(no, meal) {
      let limit;
      this.mealPrep = meal?.mealPrep || false;
      this.serves = meal?.serves || 1;

      this.originData = meal?.originData || null;

      this.plan?.hideEnergy ? (limit = no) : (limit = no + 1);
      for (let i = 1; i <= limit; i++) {
        this.$refs.carousel.next();
      }
    },

    goBack(no) {
      let limit = 3;

      if (this.plan?.hideInstructions) limit = 2;

      for (let i = no; i >= limit; i--) {
        this.$refs.carousel.prev();
      }
    },

    getIndexNo(id) {
      return this.allRecipes.findIndex((recipe) => recipe.meal.id == id);
    },

    parseMealTime(time) {
      const notSnack = ["breakfast", "lunch", "dinner", "desert"];

      if (!notSnack.includes(time)) return this.getAlternateWordSnack(time);

      // if (time == 'desert') return 'dessert';

      return this.getAlternateWord(time);
    },

    getAlternateWordSnack(val) {
      if (!this.plan?.alternateWords) return "snack";

      if (Object.prototype.hasOwnProperty.call(this.plan?.alternateWords, val)) {
        return this.plan.alternateWords[val];
      } else return "snack";
    },

    getAlternateWord(val) {
      if (!this.plan?.alternateWords) return val == "desert" ? "dessert" : val;

      if (Object.prototype.hasOwnProperty.call(this.plan?.alternateWords, val)) {
        return this.plan.alternateWords[val];
      } else return val == "desert" ? "dessert" : val;
    },

    getMealTimes(recipes) {
      let confRecipes = [];
      const order = {
        breakfast: 1,
        lunch: 2,
        dinner: 3,
        desert: 4,
        snack1: 5,
        snack2: 5,
        snack3: 5,
        snack4: 5,
      };

      const days = {
        1: "Monday",
        2: "Tuesday",
        3: "Wednesday",
        4: "Thursday",
        5: "Friday",
        6: "Saturday",
        7: "Sunday",
      };

      confRecipes = recipes.map((recipe) => {
        let mealTime = { ...recipe.meal }
        return {
          ...recipe,
          meal: {
            ...recipe.meal,
            time: this.parseMealTime(mealTime?.meal),
            order: order[mealTime?.meal],
            day: days[mealTime?.day],
          },
        };
      });

      return confRecipes;
    },

    getMealTimesAlt(meals) {
      let confMeals = [];
      const order = {
        breakfast: 1,
        snack1: 2,
        lunch: 3,
        snack2: 4,
        dinner: 5,
        desert: 6,
        snack3: 7,
        snack4: 7,
      };

      const days = {
        1: "Monday",
        2: "Tuesday",
        3: "Wednesday",
        4: "Thursday",
        5: "Friday",
        6: "Saturday",
        7: "Sunday",
      };

      confMeals = meals.map((meal) => {
        return {
          ...meal,
          time: this.parseMealTime(meal?.meal),
          order: order[meal?.meal],
          day: days[meal?.day],
        };
      });

      return confMeals;
    },
    getRecipeImg(meal) {
      // var storage = firebase.storage();
      let img = "none";

      let recipe = this.recipes.find((r) => r.id == meal);

      if (recipe?.thumbPhotoUrl) img = recipe.thumbPhotoUrl;
      else if (recipe?.mediumPhotoUrl) img = recipe.mediumPhotoUrl;
      else if (recipe?.OriginalPhotoUrl) img = recipe.OriginalPhotoUrl;
      else if (recipe?.photo) img = recipe.photo;
      else
        img =
          "https://firebasestorage.googleapis.com/v0/b/cq-nutrition.appspot.com/o/misc%2Fno_image.png?alt=media&token=9cd541b8-ce97-4b99-b8d7-905b5e198b5f";

      return img;

      // storage
      //   .ref(`recipes/medium_${recipe.photo}`)
      //   .getDownloadURL()
      //   .then((url) => {
      //     console.log(url)
      //    img = url;
      //   })
      //   .catch(() => {
      //     storage
      //       .ref(`recipes/${recipe.photo}`)
      //       .getDownloadURL()
      //       .then((url) => {
      //         console.log({ url })
      //         img = url;
      //       })
      //       .catch((error) => {
      //         console.log(error.message);
      //       });
      //   });

      // return img
    },
    getRecipeName(meal) {
      let name;

      name = "no meal";

      let recipe;

      recipe = this.recipes.find((r) => r.id == meal);

      if (recipe) name = recipe.name;

      return name;
    },

    getRecipe(meal) {
      let selectedRecipe;

      selectedRecipe = {};

      let recipe;

      recipe = this.recipes.find((r) => r.id == meal);
      if (recipe) selectedRecipe = recipe;

      return selectedRecipe;
    },

    getRecipeLink({ id, recipe }) {
      return `${window.origin}${
        this.$router.resolve({
          name: "DownloadRecipe",
          query: {
            id: recipe,
            plan: this.plan.id,
            meal: id,
            b: this.user.id,
            "hide-energy": this.plan.hideEnergy ? "true" : "false",
          },
        }).href
      }`;
    },
    getTotalNutrientValue(day, type) {
      var total = 0;
      var meals = this.unfilteredMeals.filter(
        (m) => (m.day == day || day >= 8) && m.recipe !== "no meal"
      );
      var recipeIds = meals.map((m) => m.recipe);
      var recipes = [];

      if (this.recipes?.length) {
        recipeIds.forEach((id) => {
          let rec = this.recipes.find((r) => r.id == id);
          if (rec) recipes.push(rec);
        });
      }

      if (recipes?.length) {
        total = sumBy(recipes, (r) => r.servingValues[type]);
      }

      var countMeals = uniqBy(meals, (meal) => meal.day);

      return Math.round(day >= 8 ? total / countMeals?.length : total);
    },
    downloadingPDF() {
      this.statuses.push("Downloading PDF..");
    },
    beforeDownload() {
      this.statuses.push("Download Success..");
    },
    downloadPDFFinish() {
      this.generatePdf = false;
      this.downloading = false;
      this.confirmDownload = false;

      if (parseInt(this.user?.downloadCredits) >= 1) {
        this.$store.dispatch("user/updateUserField", {
          value: this.user?.downloadCredits - 1,
          field: "downloadCredits",
          silent: true,
          hasDownloadedAMealPlan: true,
        });
      }

      if (!this.plan?.hasDownloaded)
        this.$store.dispatch("mealplans/addHasDownloaded", { plan: this.plan });

      this.$store.dispatch("mealplans/countDownload", {
        meals: this.meals.filter((m) => m.recipe !== "no meal"),
        goal: this.goal,
        plan: this.plan,
      });
    },
    mealPlanDownload() {
      this.statuses = [];
      this.confirmDownload = true;

      // if (!this.$store.getters['subscriptions/subscribed'])
      //   this.$root.$emit('showSubscribeDialog');
      // else
    },
    downloadConfirmed() {
      this.downloading = true;
      this.generatePdf = true;
      this.statuses.push("Starting Download..");
    },
    startDownloadRecipes() {
      this.index = 0;
      this.downloadProgress = 0;
      this.zipDialog = true;
      zip = new JSZip();
      
      // Start the download process with a slight delay to ensure the dialog is shown
      setTimeout(() => {
        this.processRecipes();
      }, 100);
    },
    
    async processRecipes() {
      try {
        // Get all valid meals
        const meals = this.meals.filter((m) => m.recipe !== "no meal");
        const totalMeals = meals.length;
        
        if (totalMeals === 0) {
          console.warn("No valid meals to download");
          this.zipDialog = false;
          return;
        }
        
        // Create a visual progress indicator
        this.downloadProgress = 5; // Start at 5% to show activity
        
        // Process each meal one by one
        for (let i = 0; i < totalMeals; i++) {
          const meal = meals[i];
          
          // Update UI with current recipe being processed
          this.index = i;
          
          // Update progress - we'll allocate 90% of the progress bar to recipe processing
          // and reserve the last 5% for zip generation
          this.downloadProgress = 5 + Math.round((i / totalMeals) * 90);
          
          // Find recipe
          const recipe = this.recipes.find((r) => r.id === meal.recipe);
          
          // Skip if recipe not found
          if (!recipe || !Object.keys(recipe).length) {
            console.warn(`Recipe not found for meal at index ${i}, skipping...`);
            continue;
          }
          
          try {
            // Update state for PDF generation
            this.recipe = recipe;
            this.serves = meal.serves;
            this.image = recipe.OriginalPhotoUrl || recipe.mediumPhotoUrl;
            
            // Force UI update before starting PDF generation
            await this.forceUIUpdate();
            
            // Generate PDF with timeout protection
            const doc = await this.generatePDFWithTimeout(recipe);
            
            // Add to zip if successful
            if (doc) {
              zip.file(`${recipe.name}.pdf`, doc.output("blob"));
              console.log(`Added ${recipe.name} to zip (${i+1}/${totalMeals})`);
            }
            
            // Force UI update after PDF generation
            await this.forceUIUpdate();
            
          } catch (error) {
            console.error(`Error processing recipe "${recipe?.name || 'unknown'}":`, error);
            // Continue with next recipe despite error
          }
        }
        
        // Generate and save the zip file
        this.downloadProgress = 95; // Show we're finalizing
        await this.finalizeZip();
        
      } catch (error) {
        console.error("Error in processRecipes:", error);
        this.zipDialog = false;
      }
    },
    
    // Force UI update by waiting a small amount of time
    forceUIUpdate() {
      return new Promise(resolve => {
        this.$nextTick(() => {
          setTimeout(resolve, 50);
        });
      });
    },
    
    // Generate PDF with a timeout to prevent hanging
    generatePDFWithTimeout(recipe) {
      return new Promise((resolve, reject) => {
        // Set a timeout to prevent hanging
        const timeoutId = setTimeout(() => {
          console.warn(`PDF generation timed out for recipe "${recipe.name}"`);
          resolve(null); // Resolve with null to continue processing
        }, 30000); // 30 second timeout
        
        // Attempt to generate the PDF
        this.downloadPDF(true)
          .then(doc => {
            clearTimeout(timeoutId);
            resolve(doc);
          })
          .catch(error => {
            clearTimeout(timeoutId);
            reject(error);
          });
      });
    },

    async finalizeZip() {
      try {
        this.downloadProgress = 95; // Show we're finalizing
        
        // Generate the zip file
        const content = await zip.generateAsync({ 
          type: "blob",
          compression: "DEFLATE", // Use compression
          compressionOptions: {
            level: 6 // Balanced between speed and compression
          }
        });
        
        // Save the file
        saveAs(content, "recipes.zip");
        
        // Show completion
        this.downloadProgress = 100;
        
        // Close dialog after a short delay
        setTimeout(() => {
          this.zipDialog = false;
          this.downloadProgress = 0;
        }, 1000);
        
      } catch (error) {
        console.error("Error generating zip:", error);
        this.zipDialog = false;
      }
    },

    showOriginalRec() {
      if (!this.originData) return;

      let meal = this.meals.find((m) => m.id === this.originData?.id);

      this.serves = meal?.serves;
      this.mealPrep = meal?.mealPrep;
    },
    setCustomRecData(recipe) {
      this.customRecipeData = recipe;
      this.customRecipeIng = recipe.ingredients;
      this.customRecipeDialog = true;

      // this.$store.dispatch("recipe/getRecipeIngredients", recipe)
    },
    setCustomServeData(recipe) {
      this.customRecipeData = recipe;
      this.customServesDialog = true;

      if(this.hasCustomServes(recipe.id)) this.customRecipeServes = recipe?.customServe;
      else if (recipe?.lock_serve) this.customRecipeServes = recipe.numberOfServes;
      else this.customRecipeServes = this.customRecipeData.meal?.serves
    },
    insertRecipe() {
      if (this.$refs.insertRecipeForm.validate()) {
        this.$store.commit("recipe/insertIngredient");
        this.$refs.insertRecipeForm.resetValidation();
      }
    },
    toggleDeleteIngredients(ing) {
      this.deleteIngredient(ing);
    },
    removeIngredient(index) {
      this.customRecipeIng.splice(index, 1);
    },

    async customizeRecipe() {
      let allIngredients = [], customInfos = [], customTags = [];

      if (this.plan?.customRecipesData) {
        allIngredients = this.plan?.customRecipesData;

        allIngredients = allIngredients.filter(
          (ing) => ing.recipeId != this.customRecipeData.id
        );
      }

      if(this.plan?.customRecipesInfo) {
        customInfos = this.plan?.customRecipesInfo

        customInfos = customInfos.filter(
          (ing) => ing.recipeId != this.customRecipeData.id
        );
      }

      if(this.plan?.customTags) {
        customTags = this.plan?.customTags

        customTags = customTags.filter(
          (ing) => ing.recipeId != this.customRecipeData.id
        );
      }

      let customIds = []

      if(this.plan?.customRecipesIds)
        customIds = [...this.plan?.customRecipesIds]

      const formatIng = this.ingredients.map((ing) => {
        customIds.push(ing.food.id)
        return {
          food: ing.food.name,
          portion: ing.portion.name,
          quantity: (ing.quantity * this.customRecipeData.numberOfServes) / (this.customRecipeData?.customServe || 1),
          id: ing.food.id
        };
      });

      let ingredients;
      ingredients = [...this.customRecipeIng, ...formatIng];
      ingredients = ingredients.map((ing, i) => {
        return {
          ...ing,
          order: i,
        };
      });

      let data = {
        recipeId: this.customRecipeData.id,
        ingredients,
      };

      allIngredients.push(data);

      if(this.customRecipeData?.customServe)
        this.$store.commit('recipe/setData', {...this.customRecipeData, numberOfServes: this.customRecipeData.customServe })  
      else
        this.$store.commit('recipe/setData', {...this.customRecipeData, numberOfServes: 1 })  

      // CALCULATE FOR NEW INFO NUTRITION

      // this.customRecipeIng.forEach(async (ing) => {
      //   let food, portion

      //   food = this.ings.find((i) => i.name == ing.food)

      //   portion = this.portions.find((p) => p.name == ing.portion && p.food == ing?.food)

      //   if(!portion) {
      //     portion = {
      //       id: 'gram',
      //       name: 'gram',
      //       unit: 'g',
      //       weight: 1
      //     }
      //   }

      //   this.$store.commit('recipe/addIngredient', {
      //     food,
      //     portion,
      //     quantity: ing.quantity
      //   })
        
      // })

      const { carbohydrate, energy, fat, fibre, protein, sodium, sugar, weight } = this.customRecipeData.servingValues

      const { carbohydrate: carbohydratePerHundredGram, energy: energyPerHundredGram, fat :fatPerHundredGram, fibre: fibrePerHundredGram, protein: proteinPerHundredGram, sodium: sodiumPerHundredGram, sugar: sugarPerHundredGram, } = this.customRecipeData.servingValuesPerHundredGram

      const serving = {
        weight: parseFloat(this.getTotalWeight) + weight,
        energy: parseFloat(this.getTotalEnergy) + energy,
        protein: parseFloat(this.getTotalProtein) + protein,
        fat: parseFloat(this.getTotalFat) + fat,
        carbohydrate: parseFloat(this.getTotalCarbohydrate) + carbohydrate,
        sugar: parseFloat(this.getTotalSugar) + sugar,
        fibre: parseFloat(this.getTotalFibre) + fibre,
        sodium: parseFloat(this.getTotalSodium) + sodium
      }
      const hundredGram = {
        energy: parseFloat(this.unFormattedCalcPerHundredGram(this.getTotalEnergy)) + energyPerHundredGram,
        protein: parseFloat(this.calcPerHundredGram(this.getTotalProtein)) + proteinPerHundredGram,
        fat: parseFloat(this.calcPerHundredGram(this.getTotalFat)) + fatPerHundredGram,
        carbohydrate: parseFloat(this.calcPerHundredGram(this.getTotalCarbohydrate)) + carbohydratePerHundredGram,
        sugar: parseFloat(this.calcPerHundredGram(this.getTotalSugar)) + sugarPerHundredGram,
        fibre: parseFloat(this.calcPerHundredGram(this.getTotalFibre)) + fibrePerHundredGram,
        sodium: parseFloat(this.calcPerHundredGram(this.getTotalSodium)) + sodiumPerHundredGram
      }

      customInfos.push({
        serving,
        hundredGram,
        recipeId: this.customRecipeData.id
      })

      this.checkNutritionTags()
      this.checkMealTags()

      customTags.push({
        nutriTags: this.customNutriTags,
        mealTags: this.customMealTags,
        recipeId: this.customRecipeData.id
      })

      await Promise.resolve(
        this.addCustomizationData({
          meal: this.plan,
          recipeId: this.customRecipeData.id,
          recipeData: allIngredients,
          customInfo: customInfos,
          customIds: customIds,
          customTags: customTags
        })
      );

      const res = await this.getPlanToDownload(this.$route.query.i);
      this.plan = res;

      // this.redisplayIng();
      // this.redisplayNutriInfo();
      // this.redisplayTags();

      this.redisplayRecipes()
      // this.removeAllIngs();      

      this.$store.commit('recipe/resetIngredients');

      this.customNutriTags = []
      this.customMealTags = []

      this.customRecipeDialog = false;
    },
    async customizeServes () {
      let allServes = [];

      if (this.plan?.customServesData) {
        allServes = this.plan?.customServesData;

        allServes = allServes.filter(
          (serve) => serve.recipeId != this.customRecipeData.id
        );
      }

      let data = {
        recipeId: this.customRecipeData.id,
        customServe: this.customRecipeServes,
      };

      allServes.push(data);

      await Promise.resolve(
        this.addCustomServesData({
          meal: this.plan,
          recipeId: this.customRecipeData.id,
          servesData: allServes,
        })
      );

      const res = await this.getPlanToDownload(this.$route.query.i);
      this.plan = res;

      this.redisplayServes();

      this.customServesDialog = false;
    },

    redisplayServes() {
      this.recipes = this.recipes.map((recipe) => {
        if(this.plan?.customServesRecipe.includes(recipe.id)) {
          let serveData = this.plan?.customServesData.find((r) => r.recipeId == recipe.id);

          return {
            ...recipe,
            customServe: serveData.customServe
          }
        }

        return recipe;
      })
    },

    removeAllIngs() {
      this.ingredients.forEach((ing) => {
        this.deleteIngredient(ing);
      });
    },

    redisplayRecipes() {
      this.recipes = this.recipes.map((recipe) => {
        if (this.plan?.customRecipes.includes(recipe.id)) {

          let ingData = this.plan?.customRecipesData.find((r) => r.recipeId == recipe.id);

          let infoData = this.plan?.customRecipesInfo.find((r) => r.recipeId == recipe.id);

          let tagsData = this.plan?.customTags.find((r) => r.recipeId == recipe.id);

          console.log({ tagsData })

          return {
            ...recipe,
            ingredients: ingData.ingredients,
            servingValues: {...infoData?.serving},
            servingValuesPerHundredGram: {...infoData?.hundredGram},
            nutritionTags: tagsData?.nutriTags ? [...tagsData?.nutriTags] : recipe.nutritionTags,
            tags: tagsData?.mealTags ? [...tagsData?.mealTags] : recipe.tags,
          };
        }

        return recipe;
      });
    },

    // redisplayIng() {
    //   this.recipes = this.recipes.map((recipe) => {
    //     if (this.plan?.customRecipes.includes(recipe.id)) {
    //       let ingData = this.plan?.customRecipesData.find((r) => r.recipeId == recipe.id);

    //       return {
    //         ...recipe,
    //         ingredients: ingData.ingredients,
    //       };
    //     }

    //     return recipe;
    //   });
    // },

    // redisplayNutriInfo(){
    //   this.recipes = this.recipes.map((recipe) => {
    //     if (this.plan?.customRecipes.includes(recipe.id)) {
    //       let ingData = this.plan?.customRecipesInfo.find((r) => r.recipeId == recipe.id);

    //       return {
    //         ...recipe,
    //         servingValues: {...ingData?.serving},
    //         servingValuesPerHundredGram: {...ingData?.hundredGram},
    //       };
    //     }

    //     return recipe;
    //   });
    // },

    showDialog() {
      this.$refs.findIngredient.dialog = true;
    },
    hasCustomServes(id) {
      if(!this.plan?.customServesRecipe) return false

      return this.plan?.customServesRecipe.includes(id);
    },
    hasCustomIng(recipe) {
      if(!this.plan?.customRecipes) return false

      return this.plan?.customRecipes.includes(recipe.id);
    },

    async resetRecipe(recipe) {
      let customRecipes, customRecipesData, customRecipesInfo = [], customTags = []


      this.customRecipeData = recipe;
      this.customRecipeIng = recipe.ingredients;

      customRecipes = this.plan.customRecipes.filter(r => r != this.customRecipeData.id)


      customRecipesData = this.plan.customRecipesData.filter(r => r.recipeId != this.customRecipeData.id)

      if(this.plan?.customRecipesInfo)
        customRecipesInfo = this.plan.customRecipesInfo.filter(r => r.recipeId != this.customRecipeData.id)


      if(this.plan?.customTags)
        customTags = this.plan.customTags.filter(r => r.recipeId != this.customRecipeData.id)


      await Promise.resolve(this.$store.dispatch('mealplans/updateMealField', {
        value: customRecipes,
        field: 'customRecipes',
        meal: this.plan,
        silent: true
      }))

      await Promise.resolve(this.$store.dispatch('mealplans/updateMealField', {
        value: customRecipesData,
        field: 'customRecipesData',
        meal: this.plan
      }))

      await Promise.resolve(this.$store.dispatch('mealplans/updateMealField', {
        value: [],
        field: 'customRecipesIds',
        meal: this.plan
      }))

      await Promise.resolve(this.$store.dispatch('mealplans/updateMealField', {
        value: customRecipesInfo,
        field: 'customRecipesInfo',
        meal: this.plan
      }))

      await Promise.resolve(this.$store.dispatch('mealplans/updateMealField', {
        value: customTags,
        field: 'customTags',
        meal: this.plan
      }))

      const res = await this.getPlanToDownload(this.$route.query.i);
      this.plan = res;

      this.removeCustoms(this.customRecipeData.id)

      this.removeFromShoppingList(this.customRecipeData.id)

      this.customRecipeDialog = false;

    },

    removeCustoms(id) {
      let unfilteredRecipe = this.unfilteredRecipes.find(r => r.id == id)

      this.recipes = this.recipes.map((recipe) => {
        if (recipe.id == id) {
          return {
            ...recipe,
            ingredients: [...unfilteredRecipe.ingredients],
            servingValues: {...unfilteredRecipe?.servingValues},
            servingValuesPerHundredGram: {...unfilteredRecipe?.servingValuesPerHundredGram},
            nutritionTags: {...unfilteredRecipe?.nutritionTags},
            tags: {...unfilteredRecipe?.tags},
          }
        }

        return recipe;
      })

    },

    closeCustomRecDialog() {

      if (this.hasNewIngs()) {
        this.customizeRecipe()
      }
      else {
        this.customRecipeIng = [];
      }

      this.customRecipeDialog = false;
    },

    hasNewIngs() {
      let origIng, recipe

      if (this.ingredients.length) return true

      else if (this.hasCustomIng(this.customRecipeData))
        recipe = this.plan.customRecipesData.find(r => r.recipeId == this.customRecipeData.id)
      else
        recipe = this.unfilteredRecipes.find(r => r.id == this.customRecipeData.id)

      origIng = recipe.ingredients.map((ing) => ing.food)

      let newIng = this.customRecipeIng.map((ing) => ing.food)

      let diff = origIng.filter(x => !newIng.includes(x))

      return !!diff.length
    },

    calcPerHundredGram(value) {
      let serves

      if(this.customRecipeData?.customServes) serves = this.customRecipeData?.customServes
      else serves = this.customRecipeData.meal?.serves

      let total =  value / serves
      let weight = this.gramPerServe / 100
      total = value / weight
      return this.$options.filters.numberFormat(total ? total.toFixed(2) : 0)
    },

    unFormattedCalcPerHundredGram(value) {
      let serves

      if(this.customRecipeData?.customServes) serves = this.customRecipeData?.customServes
      else serves = this.customRecipeData.meal?.serves

      let total =  value / serves
      let weight = this.gramPerServe / 100
      total = value / weight
      return Math.round(total)
    },
    checkNutritionTags() {
      if (this.customRecipeData.mealTime && this.customRecipeData.mealTime.length && this.ingredients && this.ingredients.length) {
        this.nutritionTags.forEach(tag => {
          if (tag.main || tag.hundred || tag.snack) {
            this.minerals.forEach(mineral => {
              // MAIN MEALS NUTRITION TAGS

              if (intersection(['Breakfast', 'Lunch', 'Dinner'], this.customRecipeData.mealTime).length && tag.main && tag.main.name == mineral.name) {
                if (tag.main && tag.main.name == 'Weight') {
                  if (tag.mainCondition == '≤') {
                    if (
                        (parseFloat(this.tagSmallWeight.mainAmount) >= parseFloat(tag.mainAmount) && parseFloat(tag.mainAmount) >= mineral.value) ||
                        (this.tagSmallWeight.id && mineral.value > this.tagSmallWeight.mainAmount && parseFloat(tag.mainAmount) >= mineral.value)
                    ) {
                      if (this.tagSmallWeight.id) this.removeNutriTags(this.tagSmallWeight.id)

                      Object.assign(this.tagSmallWeight, tag)
                      this.insertCustomNutriTags(this.tagSmallWeight.id)
                    }
                    else {
                      this.removeNutriTags(tag.id)
                    }
                  } 
                  else if (tag.mainCondition == '≥') {
                    if (
                      (parseFloat(tag.mainAmount) >= parseFloat(this.tagLargeWeight.mainAmount) && mineral.value >= parseFloat(tag.mainAmount)) ||
                      (this.tagLargeWeight.id && mineral.value < this.tagLargeWeight.mainAmount && parseFloat(tag.mainAmount) <= mineral.value)
                    ) {
                      if (this.tagLargeWeight.id) this.removeNutriTags(this.tagLargeWeight.id)
                      
                      Object.assign(this.tagLargeWeight, tag)
                      this.insertCustomNutriTags(this.tagLargeWeight.id)
                    }
                    else {
                      this.removeNutriTags(tag.id)
                    }
                  }
                  
                }
                else if ((tag.mainCondition == '≤' && mineral.value <= parseFloat(tag.mainAmount)) || 
                    (tag.mainCondition == '≥' && mineral.value >= parseFloat(tag.mainAmount))) {
                  this.insertCustomNutriTags(tag.id)
                }
                else {
                  this.removeNutriTags(tag.id)
                }

                // RANGE TAGS
                if(tag.range) {

                  if(mineral.value >= parseFloat(tag.mainAmount) && mineral.value <= parseFloat(tag.secondAmount)) {
                    this.insertCustomNutriTags(tag.id)
                  }
                  else {
                    this.removeNutriTags(tag.id)
                  }
                }

              }
              
              // SNACK NUTRITION TAGS
              if (this.customRecipeData.mealTime.includes('Snack') && tag.snack && tag.snack.name == mineral.name) {
                if ((tag.snackCondition == '≤' && mineral.value <= parseFloat(tag.snackAmount)) || 
                    (tag.snackCondition == '≥' && mineral.value >= parseFloat(tag.snackAmount))) {
                  this.insertCustomNutriTags(tag.id)
                }
                else {
                  this.removeNutriTags(tag.id)
                }

                if(tag.range && tag.snackAmount) {
                  if(mineral.value >= parseFloat(tag.snackAmount) && mineral.value <= parseFloat(tag.snackSecondAmount)) {
                    this.insertCustomNutriTags(tag.id)
                  }
                  else {
                    this.removeNutriTags(tag.id)
                  }
                }
              }
              
              // MEAL NUTRITION PER HUNDRED GRAM
              if (this.customRecipeData.mealTime && this.customRecipeData.mealTime.length && tag.hundred && tag.hundred.name == mineral.name) {
                if ((tag.hundredCondition == '≤' && this.calcPerHundredGram(mineral.value) <= parseFloat(tag.hundredAmount)) || 
                    (tag.hundredCondition == '≥' && this.calcPerHundredGram(mineral.value) >= parseFloat(tag.hundredAmount))) {
                  this.insertCustomNutriTags(tag.id)
                }
                else {
                  this.removeNutriTags(tag.id)
                }

                if(tag.range && tag.hundredAmount) {
                  if(parseFloat(this.calcPerHundredGram(mineral.value)) >= parseFloat(tag.hundredAmount) && this.calcPerHundredGram(mineral.value) <= parseFloat(tag.hundredSecondAmount)) {
                    this.insertCustomNutriTags(tag.id)
                  }
                  else {
                    this.removeNutriTags(tag.id)
                  }
                }
              }

            })
          }

          // NEW TAGS
          if(tag.switchToBool) {
            // GET ALL THE TAGS
            if(this.ingredients.length) {
              console.log('-----------NUTRITION TAGS---------')
              const tags = this.ingredients.map(ing => ing.food[tag.mainBool])

              console.log(tag.name)
              console.log(tags)

              if(tags.every(t => t.toLowerCase() === tag.mainBoolVal.toLowerCase())){
                this.insertCustomNutriTags(tag.id)
              }
              else {
                this.removeNutriTags(tag.id)
              }
            }
          }

        })

      }
    },

    checkMealTags () {
      if(this.ingredients.length) {
      console.log('-----------MEAL TAGS---------')
        this.mealTags.forEach(tag => {
          if(this.mealTagsFromExcel.includes(tag.name)) {
            console.log({ ingredients: this.ingredients })
            const tags = this.ingredients.map(ing => {
              return ing.food[tag.name]
            })

            console.log(tag.name)
            console.log(tags)

            if(tags.every(t => t.toLowerCase() === 'yes')) {
              this.insertCustomMealTag(tag.id)
            } else {
              this.removeCustomMealTag(tag.id)
            }
          }
        })

        // 6 ingredients or less Tag
        if(this.ingredients.length <= 6)
          this.insertCustomMealTag('cVTWVj4Rj1IwlxuNy07L')
        else
          this.removeCustomMealTag('cVTWVj4Rj1IwlxuNy07L')


        // Simple Tag
        if(this.ingredients.length <= 6 && this.customRecipeData.methods.length <= 6) 
          this.insertCustomMealTag('iTOmeAWBl7iC6y63pU3t')
        else
          this.removeCustomMealTag('iTOmeAWBl7iC6y63pU3t')

      }
    },

    insertCustomNutriTags(id) {
      if(!this.customNutriTags.find(tag => tag == id)) this.customNutriTags.push(id)
    },

    removeNutriTags(id) {
      if(this.customNutriTags) {
        let tag = this.customNutriTags.find(t => t == id)

        if(tag) { 
          this.customNutriTags.splice(this.customNutriTags.indexOf(tag), 1)
        }
      }
    },

    insertCustomMealTag(id) {
      if(!this.customMealTags.find(tag => tag == id)) this.customMealTags.push(id)
    },

    removeCustomMealTag(id) {
      if(this.customMealTags) {
        let tag = this.customMealTags.find(t => t == id)

        if(tag) { 
          this.customMealTags.splice(this.customMealTags.indexOf(tag), 1)
        }
      }
    }

  },

  mounted() {
    if (!this.$store.state.tags.status.firstLoad) this.$store.dispatch("tags/getTags");

    this.$store.dispatch("nutritiontags/getTags");
    this.$store.dispatch("foods/getCategories");

    this.getPlanToDownload(this.$route.query.i).then( async (res) => {
      const { user, mainMeals, meals, goal } = res.linkDetails;

      await this.getUserInfo(user.id)

      await Promise.resolve(this.getPayments(user.id));

      await Promise.resolve(this.getSubscriptions(user.id));

      setTimeout(() => {
        this.loadingWarning = false
      }, 4000)

      Promise.resolve(this.$store.dispatch("user/getUserDataFromId", user.id)).then(
        async (data) => {
          let recipes = [], filteredRecipes, unfilteredRecipes = [];

          this.plan = res;
          this.user = data;
          this.mainMeals = mainMeals;
          // this.meals = uniqBy(meals, (meal) => meal.recipe);
          this.meals = meals
          this.unfilteredMeals = meals;
          this.goal = goal;

          await Promise.all(
            meals.map(async (meal) => {
              let recipe = await Promise.resolve(
                this.$store.dispatch("recipes/getRecipeEbook", {
                  id: meal.recipe,
                  ingredients: true,
                })
              );

              recipes.push({...recipe, meal});
            })
          );

          recipes.forEach((recipe) => {
            if(recipe?.ingredients?.length) {
              unfilteredRecipes.push({
                ...recipe,
                ingredients: [...recipe.ingredients]
              })
            }
          });

          this.unfilteredRecipes = unfilteredRecipes

          filteredRecipes = orderBy(
            this.getMealTimes(recipes),
            "meal.order",
            "asc"
          );

          // const findthis = filteredRecipes.find((r) => r.id == 'M3xKVZqE2zLl9UexwuiG');
          // console.log({ filteredRecipes})

          if (res?.customRecipes?.length) {
            filteredRecipes = filteredRecipes.map((recipe) => {
              if (res?.customRecipes.includes(recipe.id)) {
                let ingData = res?.customRecipesData.find((r) => r.recipeId == recipe.id);

                return {
                  ...recipe,
                  ingredients: ingData.ingredients,
                };
              }

              return recipe;
            });
          }

          if(res?.customServesRecipe?.length) {
            filteredRecipes = filteredRecipes.map((recipe) => {
              if(res?.customServesRecipe.includes(recipe.id)) {
                let serveData = res?.customServesData.find((r) => r.recipeId == recipe.id);

                return {
                  ...recipe,
                  customServe: serveData.customServe,
                }
              }

              return recipe;
            })
          }

          if(res?.customRecipesInfo?.length) {
            filteredRecipes = filteredRecipes.map((recipe) => {
              if (res?.customRecipes.includes(recipe.id)) {
                let infoData = res?.customRecipesInfo.find((r) => r.recipeId == recipe.id);

                return {
                  ...recipe,
                  servingValues: {...infoData?.serving},
                  servingValuesPerHundredGram: {...infoData?.hundredGram},
                };
              }

              return recipe;
            });
          }

          if(res?.customTags?.length) {
            filteredRecipes = filteredRecipes.map((recipe) => {
              if (res?.customRecipes.includes(recipe.id)) {
                let tagsData = res?.customTags.find((r) => r.recipeId == recipe.id);

                if(!tagsData) return recipe

                return {
                  ...recipe,
                  nutritionTags: [...tagsData?.nutriTags],
                  tags: [...tagsData?.mealTags],
                };
              }

              return recipe;
            });
          }

          // if(res?.customRecipesIds?.length) {
          //   this.$store.dispatch('ingredients/getFoods', [...res?.customRecipesIds])
          // }

          filteredRecipes = filteredRecipes.filter((r) => r?.id);
          this.recipes = filteredRecipes;


          //   this.recipes = orderBy(this.getMealTimes(uniqBy(recipes, (recipe) => recipe.name), meals))

          this.filteredMeals = orderBy(this.getMealTimesAlt(meals), "meal.order", "desc");

          this.plan = res;

          this.recipes.forEach((recipe) => {
            this.$store.dispatch("recipes/getRecipeIngredientsForEbook", recipe);
          });
        }
      );
    });
  },
};
</script>
<style lang="scss" scoped>
$primary: #97c81f;
$black: rgba(0, 0, 0, 0.87);

* {
  box-sizing: border-box;
}

.put_center {
  display: grid;
  place-items: center;
}

.custom-card {
  position: relative;
}

.bg {
  height: 5%;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
}
.bg-text {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 10px;
  padding: 0 10px;
  color: #fff;
}

.sample-text {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 50px;
  font-weight: bold;
  padding: 0 10px;
  color: #fff;
}

.social-medias {
  width: 100%;
  position: absolute;
  right: 0;
  bottom: 10px;
  padding: 0 10px;
  color: #fff;
}

.shoppingcart {
  position: absolute;
  right: 30px;
  padding: 0 10px;
  color: #fff;
}

.table-content-item {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.stamp-container {
  height: 100px;
}

.stamp-seal {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
}

$badge-font-size: 8px;
</style>
